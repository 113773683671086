// 미디어 쿼리
// ================== min-width: 360px 이상 ==================
@media (min-width: 400px) {
  // 메인
  #home {
    .home-container {
      .main-section {
        .main-container {
          .main-contents {
            .main-top {
              .main-sub-img {
                img {
                  max-width: 338px;
                }
              }
            }
          }
        }
      }
      .contents-section1 {
        .contents-container {
          .contents-list {
            .contents-item {
              .contents-img {
                height: auto;
                min-height: 148px;
                max-height: 282px;
                img {
                  height: auto;
                  max-height: 282px;
                }
              }
            }
          }
        }
      }
    }
  }
  // 한의원 소개
  #introduction {
    .main-section {
      .main-img {
        height: auto;
        min-height: 224px;
        max-height: 400px;
        img {
          height: auto;
          min-height: 224px;
        }
      }
    }
    .contents-box {
      .contents-section {
        .contents-img {
          height: auto;
          min-height: 176px;
          max-height: 600px;
          img {
            height: auto;
            min-height: 176px;
          }
        }
      }
    }
  }
  // 치료 후기
  #review {
    .main-section {
      .main-img {
        height: auto;
        min-height: 176px;
        img {
          height: auto;
          min-height: 176px;
        }
      }
    }
    .contents-section {
      .contents-container {
        .contents-box {
          .contents-list {
            .contents-item {
              .contents-img {
                height: 241px;
                img {
                  height: 241px;
                }
              }
            }
          }
        }
      }
    }
  }
  // 치료 후기 상세
  #review-detail {
    .main-section {
      .main-img {
        height: auto;
        min-height: 176px;
        img {
          height: auto;
          min-height: 176px;
        }
      }
    }
  }
  // 공지사항
  #notices {
    .main-section {
      .main-img {
        height: auto;
        min-height: 176px;
        img {
          height: auto;
          min-height: 176px;
        }
      }
    }
  }
  // 공지사항 상세
  #noticeDetail {
    .main-section {
      .main-img {
        height: auto;
        min-height: 176px;
        img {
          height: auto;
          min-height: 176px;
        }
      }
    }
  }
  // 자주 묻는 질문
  #faqs {
    .main-section {
      .main-img {
        height: auto;
        min-height: 176px;
        img {
          height: auto;
          min-height: 176px;
        }
      }
    }
  }
  // 상담 예약
  #reservations {
    .main-section {
      .main-img {
        height: auto;
        min-height: 176px;
        img {
          height: auto;
          min-height: 176px;
        }
      }
    }
  }
  // 상담 예약 상세
  #reservations-write {
    .main-section {
      .main-img {
        height: auto;
        min-height: 176px;
        img {
          height: auto;
          min-height: 176px;
        }
      }
    }
  }
  // 상담 예약 성공
  #reservations-clear {
    .main-section {
      .main-img {
        height: auto;
        min-height: 176px;
        img {
          height: auto;
          min-height: 176px;
        }
      }
    }
  }
}
// ================== min-width: 500px 이상 ==================
@media (min-width: 500px) {
  #home {
    .home-container {
      .main-section {
        .main-container {
          .main-contents {
            .main-bottom {
              .main-btn {
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
                // .btn-primary {
                //   margin-bottom: 0;
                // }
              }
            }
          }
        }
      }
    }
  }
  .br-500 {
    display: none;
  }
  .br-pc {
    display: block;
  }
}
// ================== min-width: 768px 이상 ==================
@media (min-width: 768px) {
  #doctors {
    .main-section {
      .pc-main-img {
        display: block;
      }
      .m-main-img {
        display: none;
      }
    }
    .contents-box {
      .contents-section {
        .contents-container {
          .contents-list-box {
            .contents-item-img {
              height: 500px;
            }
          }
        }
      }
    }
  }
  #directions {
    .contents-section {
      .directions-flex {
        display: flex;
        gap: 0.5rem;
      }
      .contents-container {
        .contents-3 {
          .width-78 {
            min-width: 78px !important;
          }
        }
      }
    }
  }
}
// ================== min-width: 1024px 이상 ==================
@media (min-width: 1024px) {
  .br-pc-1024 {
    display: block;
  }
  // 헤더
  #header {
    height: 120px;
    &.header-home {
      .header-container {
        position: relative;
        &.inner {
          width: 1000px;
          margin: 0 auto;
          display: block;
          .header-top {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .home-logo {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }
            .header-box {
              .btn-outline-primary {
                display: block;
                margin-right: 1rem;
              }
              .btn-mobile {
                display: none;
              }
            }
          }
          .mobile-tab {
            display: none;
          }
          .header-bottom {
            display: block;
            ul {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 3rem;
              li {
                font-size: var(--fs-18);
                font-weight: 500;
                color: #fff;
                line-height: 2rem;
              }
            }
          }
        }
      }
    }
    // 헤더 스크롤 되었을 때
    &.scrolled {
      background-color: #fff;
      .header-container {
        &.inner {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .logo {
            position: static;
          }
          .header-nav {
            ul {
              display: flex;
              justify-content: flex-start;
              align-items: center;
            }
          }
          .header-bottom {
            display: none;
          }
        }
      }
    }
    .header-container {
      position: relative;
      &.inner {
        width: 1000px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
          position: static;
        }
        .header-nav {
          height: 100%;
          display: block;
          .header-list {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            li {
              font-size: var(--fs-18);
              font-weight: 500;
              color: #252525;
              line-height: 2rem;
              &.header-item {
                min-height: 34px;
                transition: all 0.1s;
                &:hover {
                  border-bottom: 2px solid #d79262;
                }
              }
              a {
                display: block;
                min-height: 34px;
                color: #252525;
                transition: all 0.1s;
              }
              &:hover {
                color: #d79262;
                a {
                  color: #d79262;
                }
              }
            }
            .sub-item {
              height: 100%;
              display: flex;
              align-items: center;
              transition: all 0.1s;
              padding: 0 2rem;
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
              &:hover {
                .sub-title {
                  border-bottom: 2px solid #d79262;
                }
              }
              &.active {
                color: #d79262;
                .sub-title {
                  border-bottom: 2px solid #d79262;
                }
              }
              .sub-title {
                min-height: 34px;
                display: flex;
                align-items: center;
                transition: all 0.1s;
                cursor: pointer;
                font-size: var(--fs-18);
                font-weight: 500;
                line-height: 2rem;
                p {
                  min-height: 34px;
                }
                .icon {
                  height: 4px;
                  display: flex;
                  padding-left: 0.5rem;
                }
              }
              .menu {
                position: fixed;
                left: 0;
                top: 120px;
                width: 100%;
                height: 60px;
                background-color: #fff;
                display: flex;
                justify-content: center;
                display: none;
                box-shadow: 10px 10px 10px 0px rgba(0, 0, 0, 0.2);
                ul {
                  display: flex;
                  align-items: center;
                  gap: 7.5rem;
                  li {
                    font-size: var(--fs-16);
                    line-height: 1rem;
                    cursor: pointer;
                    &:hover {
                      a {
                        color: #d79262;
                      }
                    }
                    a {
                      color: #252525;
                    }
                  }
                  &.inner {
                    width: 1000px;
                    margin: 0 auto;
                    padding-left: 10rem;
                  }
                  &.inner-right {
                    width: 1000px;
                    margin: 0 auto;
                    justify-content: flex-end;
                    padding-right: 14.5rem;
                  }
                }
              }
              &:hover {
                .menu {
                  display: flex; // 호버 시 표시
                }
              }
            }
            .sub-header-list {
              display: flex;
              align-items: center;
              gap: 2rem;
            }
          }
        }
        .header-box {
          .btn-outline-primary {
            display: block;
            margin-right: 1rem;
          }
          .btn-mobile {
            display: none;
          }
        }
        .mobile-tab {
          display: none;
        }
      }
    }
  }
  // 랜딩
  #landing {
    .landing-section {
      .landing-container {
        position: relative;
        padding: 6rem 0;
        header {
          .landing-header {
            justify-content: flex-start;
            .logo-container {
              width: 200px;
              height: 100px;
            }
          }
        }
        &.inner {
          width: 1000px;
          margin: 0 auto;
          .landing-contact {
            .landing-text {
              padding-top: 4rem;
              margin-bottom: 5rem;
              h2 {
                font-size: 2.5rem;
                font-weight: 400;
                line-height: 3.75rem;
                text-align: left;
              }
            }
            .contact-list {
              flex-direction: row;
              flex-wrap: wrap;
              width: 100%;
              margin-bottom: 0;
              gap: 1rem;
              .contact-item {
                width: 32%;
                min-height: 400px;
                max-height: 400px;
                padding: 3.375rem 1.8rem 3.375rem 2rem;
                .contact-title {
                  .title {
                    font-size: 2.25rem;
                    font-weight: 800;
                    color: #fff;
                    line-height: 2.25rem;
                    margin-bottom: 1rem;
                  }
                  .text {
                    font-size: 1.625rem;
                    font-weight: 400;
                    color: #fff;
                    line-height: 1.875rem;
                  }
                  p {
                    font-size: 1.2rem;
                    font-weight: 500;
                    line-height: 2.625rem;
                  }
                }
                .arrow {
                  height: 24px;
                }
              }
            }
            .contact-options {
              position: absolute;
              right: 0;
              top: 15%;
              .contact-option {
                padding: 1.5rem 2.5rem;
                border-radius: 3rem;
                .contact-icon {
                  height: 32px;
                }
                .contact-text {
                  font-size: 1.625rem;
                  font-weight: 500;
                  line-height: 1.875rem;
                }
              }
            }
          }
        }
      }
    }
  }
  // 메인
  #home {
    .home-container {
      padding-top: 7.5rem;
      section {
        &.inner {
          width: 1000px;
          margin: 0 auto;
          padding-left: 0;
          padding-right: 0;
        }
        &.main-section {
          .main-container {
            .main-img {
              height: 420px;
              img {
                height: 420px;
              }
            }
            .main-contents {
              &.inner {
                width: 1000px;
                left: 50%;
                transform: translateX(-50%);
                padding-top: 2.5rem;
                // padding-top: 6.5rem;
                padding-left: 0;
                padding-right: 0;
                .main-top {
                  flex-direction: row;
                  justify-content: space-between;
                  gap: 3.75rem;
                  .main-box {
                    width: 50%;
                    .title {
                      font-size: 2.875rem;
                      line-height: 3.75rem;
                      margin-bottom: 1rem;
                    }
                    p {
                      font-size: 1.375rem;
                      line-height: 2.125rem;
                      margin-bottom: 2.5rem;
                    }
                    button {
                      font-size: 1.375rem;
                      padding: 1.1875rem 0;
                    }
                  }
                  .main-sub-img {
                    width: 50%;
                    border-radius: 1rem;
                    overflow: hidden;
                    img {
                      max-width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .contents-section1 {
        padding: 10rem 0;
        .contents-container {
          h2 {
            font-size: 2.25rem;
            line-height: 2.5rem;
            margin-bottom: 2.5rem;
          }
          .contents-list {
            gap: 1.5rem;
            .contents-item {
              width: calc((100% - 4.5rem) / 4);
              .contents-img {
                height: 282px;
                img {
                  height: 282px;
                }
                .contents-text {
                  p {
                    font-size: 1.875rem;
                    font-weight: 700;
                    line-height: 2.5rem;
                  }
                }
              }
            }
          }
        }
      }
      .contents-section2 {
        .contents-container {
          height: 688px;
          .contents-box {
            padding-left: 0;
            padding-right: 0;
            &.inner {
              width: 1000px;
              margin: 0 auto;
              padding: 0;
              h2 {
                font-size: 2.375rem;
                line-height: 3.125rem;
                padding-top: 0;
              }
              hr {
                margin: 3rem 0;
              }
              p {
                font-size: var(--fs-20);
                line-height: 2rem;
                margin-bottom: 3rem;
              }
              .btn-introduction {
                font-size: 1.375rem;
                line-height: 2rem;
                padding: 1.1875rem 0;
              }
            }
          }
        }
      }
      .contents-section3 {
        padding-top: 10rem;
        padding-bottom: 5.5625rem;
        .contents-container {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          gap: 12.1875rem;
          .contents-box {
            flex: 1;
            .contents-list {
              flex-direction: row;
              gap: 1.5rem;
              .responsive-span {
                display: block;
                width: 1px;
                background-color: #252525;
              }
            }
          }
        }
      }
      .contents-section4 {
        .contents-container {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          gap: 12.1875rem;
          margin-bottom: 18.6875rem;
          .title {
            padding: 0;
          }
          .contents-box {
            flex: 1;
            .map {
              height: 296px;
            }
            .contents-list {
              .contents-item {
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
  // 한의원 소개
  #introduction {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        height: 400px;
        img {
          width: 100%;
          height: 400px;
        }
      }
    }
    .contents-box {
      .contents-section {
        .contents-img {
          height: 600px;
          border-radius: 1rem;
          overflow: hidden;
          img {
            width: 100%;
            height: 600px;
          }
        }
        .contents-container {
          padding-top: 5rem;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 10rem;
          h2 {
            font-size: 2.25rem;
            line-height: 3.125rem;
            margin-bottom: 0;
          }
          &.flex-box {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 6.25rem;
            .title {
              width: 50%;
            }
            .contents-text {
              width: 50%;
              p {
                font-size: var(--fs-20);
                line-height: 2rem;
              }
            }
          }
          &.contents-2 {
            .title {
              h2 {
                line-height: 3.125rem;
              }
            }
          }
        }
        &.top-section {
          padding-top: 5rem;
          padding-left: 0;
          padding-right: 0;
          margin-bottom: 10rem;
        }
        &.info-section {
          padding-top: 0;
          padding-left: 0;
          padding-right: 0;
          .contents-container {
            margin-bottom: 5rem;
          }
        }
        &.reservation-section {
          .contents-container {
            padding-top: 10rem;
            margin-bottom: 12.5rem;
            h2 {
              text-align: center;
            }
            .contents-text {
              margin-bottom: 2.5rem;
              p {
                font-size: var(--fs-20);
                line-height: 2rem;
                text-align: center;
              }
            }
            .btn-box {
              .title-btn {
                .btn {
                  font-size: 1.625rem;
                  padding: 1.75rem 1.5rem;
                  line-height: 2rem;
                }
              }
              .btn-outline {
                .btn-outline-primary {
                  font-size: var(--fs-20);
                  line-height: 2rem;
                }
              }
            }
          }
        }
      }
      &.inner {
        width: 1000px;
        margin: 0 auto;
      }
    }
  }
  // 의료진 소개
  #doctors {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        height: 600px;
        img {
          width: 100%;
          height: 600px;
        }
      }
    }
    .contents-box {
      padding: 0;
      .contents-section {
        .contents-container {
          padding-top: 5rem;
          margin-bottom: 12.5rem;
          .contents-list {
            margin-bottom: 10.6875rem;
            .contents-main-title {
              font-size: 2.5rem;
              padding-top: 0;
              margin-bottom: 3rem;
            }
            h4 {
              font-size: 1.875rem;
              line-height: 1.875rem;
              margin-bottom: 3rem;
              span {
                display: block;
                font-size: var(--fs-18);
                font-weight: 600;
                line-height: 1.125rem;
                margin-bottom: 1rem;
              }
            }
            .contents-detail {
              width: 100%;
              padding-top: 2rem;
              display: flex;
              gap: 6.25rem;
              .contents-title {
                max-width: 309px;
                width: 100%;
                margin-bottom: 0;
                .text {
                  .title {
                    margin-bottom: 0.5rem;
                  }
                  p {
                    line-height: 1.125rem;
                  }
                }
              }
              .contents-text {
                display: block;
                &.m-contents-text {
                  display: none;
                }
                .text {
                  font-size: var(--fs-18);
                  margin-bottom: 1rem;
                }
              }
            }
          }
          .contents-list2 {
            display: flex;
            gap: 3.5rem;
            .contents-img {
              height: 360px;
              border-radius: 0.5rem;
              overflow: hidden;
              img {
                width: 100%;
                height: 360px;
              }
            }
            .contents-info {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              .name {
                padding: 0;
                margin-bottom: 1.5rem;
              }
              .contents-item {
                .title {
                  margin-bottom: 0.5rem;
                }
              }
            }
            .m-contents-list {
              display: none;
            }
            .main-contents-list {
              display: block;
            }
          }
          .contents-list-box {
            display: flex;
            flex-direction: row;
            column-gap: 2.5rem;
            .contents-item-box {
              width: calc(50% - 1.25rem);
              .contents-item-img {
                height: 500px;
              }
              .contents-dt-list {
                display: flex;
                column-gap: 9.4375rem;
                padding-top: 2rem;
                .name {
                  padding-top: 0;
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
      &.inner {
        width: 1000px;
        margin: 0 auto;
      }
    }
    .special-section {
      margin-bottom: 5rem;
      .contents-container {
        background-position: 25%;
        min-height: 531px;
        &.pc-container {
          display: flex;
          position: relative;
          background-image: url('../images/img_doctor4.jpg');
          background-repeat: no-repeat;
          background-size: cover;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        &.m-container {
          display: none;
        }
        .contents-text-group {
          position: absolute;
          left: 50%;
          padding: 0;
          display: flex;
          gap: 8.8125rem;
          .contents-title-box {
            p {
              font-size: var(--fs-18);
              font-weight: 600;
              color: #fff;
              line-height: normal;
              margin-bottom: 1rem;
            }
            h2 {
              font-size: 1.875rem;
              font-weight: 700;
              color: #fff;
              line-height: normal;
              margin-bottom: 0;
            }
          }
          .contents-text-box {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            p {
              font-size: var(--fs-16);
              font-weight: 500;
              color: #fff;
              line-height: 2rem;
            }
            .contents-text-detail {
              font-weight: 700;
              line-height: 1rem;
            }
          }
        }
      }
      .contents-m-text-group {
        display: none;
      }
    }
  }
  // 지점 안내
  #directions {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        height: 400px;
        img {
          width: 100%;
          height: 400px;
        }
      }
    }
    .contents-section {
      padding-top: 5rem;
      .contents-container {
        .contents-box {
          display: flex;
          justify-content: space-between;
          padding: 0;
          padding-bottom: 5rem;
          .contents-list {
            .directions-item {
              &.detail {
                padding-top: 2.25rem;
              }
            }
            h2 {
              margin-bottom: 1.5rem;
            }
            .directions-detail {
              padding-top: 2.25rem;
              margin-bottom: 0;
              .sub-detail {
                margin-bottom: 1.5rem;
              }
            }
            &.contents-2 {
              margin-bottom: 0;
              .directions-item {
                display: flex;
                .title {
                  width: 20%;
                }
              }
            }
          }
          &.inner {
            width: 1000px;
            margin: 0 auto;
          }
        }
        .contents-list {
          &.contents-3 {
            // background-color: #FBF4EF;
            // padding: 4rem 0 4.3125rem 0;
            margin-top: 0;
            margin-bottom: 15.125rem;
            .contents-container {
              padding: 0;
              h2 {
                margin-bottom: 1.5rem;
              }
              .directions-box {
                display: flex;
                justify-content: space-between;
                .directions-item {
                  width: 100%;
                  display: flex;
                  gap: 1.5rem;
                  .title {
                    width: 20%;
                  }
                  p {
                    width: 80%;
                    display: flex;
                    flex-direction: column;
                  }
                }
              }
              .directions-info {
                padding-top: 2rem;
              }
              &.inner {
                width: 1000px;
                margin: 0 auto;
              }
            }
          }
        }
        .map {
          height: 400px;
          border-radius: 1rem;
          &.inner {
            width: 1000px;
            margin: 0 auto;
          }
        }
        .btn-box {
          max-width: 440px;
          padding-top: 7.5rem;
          margin-bottom: 11.5625rem;
          .btn {
            font-size: 1.625rem;
            font-weight: 700;
            line-height: 2rem;
            padding: 1.75rem 1.5rem;
            border-radius: 56px;
          }
        }
      }
    }
  }
  // 키성장
  #growth {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        height: 400px;
        img {
          width: 100%;
          height: 400px;
        }
        .main-text {
          h2 {
            font-size: 3.5rem;
            font-weight: 700;
            line-height: 3.5rem;
            margin-bottom: 1.5rem;
          }
          .text {
            font-size: 2.75rem;
            font-weight: 400;
            line-height: 2.75rem;
          }
        }
      }
    }
    .contents-section1 {
      padding: 5rem 0;
      .contents-container {
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-text {
          display: flex;
          justify-content: space-between;
          gap: 5.25rem;
          padding: 0;
          margin-bottom: 10rem;
          .sub-title {
            width: 50%;
            h2 {
              font-size: 2.25rem;
              line-height: 2.5rem;
            }
          }
          .sub-text {
            width: 50%;
            p {
              font-size: var(--fs-20);
              line-height: 2.125rem;
            }
            .text-bottom {
              margin-bottom: 1.5rem;
            }
          }
          &.last-text {
            margin-bottom: 5rem;
          }
        }
        .contents-img {
          height: 380px;
          border-radius: 0.5rem;
          overflow: hidden;
          padding: 0;
          img {
            height: 380px;
          }
        }
      }
    }
    .contents-section2 {
      margin-bottom: 10rem;
      .contents-container {
        display: flex;
        justify-content: space-between;
        gap: 6.25rem;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-text {
          width: 50%;
          padding: 0;
          p {
            font-size: var(--fs-20);
            line-height: 2.125rem;
          }
        }
        .contents-img {
          width: 50%;
          height: 800px;
          border-radius: 0.5rem;
          overflow: hidden;
          padding: 0;
          img {
            height: 800px;
          }
        }
      }
    }
    .contents-section3 {
      padding: 0;
      margin-bottom: 9.4375rem;
      .contents-container {
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-text {
          display: flex;
          justify-content: space-between;
          gap: 6.25rem;
          .sub-title {
            width: 50%;
            h2 {
              font-size: 2.25rem;
              line-height: 3.25rem;
              margin-bottom: 0;
            }
          }
          .sub-text {
            width: 50%;
            p {
              font-size: var(--fs-20);
              line-height: 2.125rem;
            }
          }
        }
      }
    }
    .contents-section4 {
      .contents-container {
        height: 780px;
        .contents-text {
          position: absolute;
          left: 50%;
          max-width: 560px;
          padding: 0;
          h2 {
            font-size: 2.25rem;
            line-height: 3.25rem;
          }
          .text {
            font-size: var(--fs-20);
            line-height: 2.125rem;
          }
          .contents-text-box {
            p {
              font-size: var(--fs-16);
              line-height: 1.625rem;
            }
          }
        }
      }
    }
    .contents-section5 {
      padding: 10rem 0;
      .contents-container {
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-text {
          .contents-img {
            width: 100px;
            margin-bottom: 0.5rem;
          }
          h2 {
            font-size: 2.25rem;
            line-height: 3.125rem;
            margin-bottom: 1rem;
          }
          p {
            font-size: 1.375rem;
            line-height: 1.875rem;
          }
        }
        .contents-box {
          display: flex;
          align-items: center;
          gap: 3.5rem;
          padding: 2rem 2rem 2rem 3.8125rem;
          .title {
            width: 35%;
            line-height: 1.75rem;
            margin-bottom: 0;
          }
          .contents-list {
            width: 65%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1.5rem 2rem;
            .contents-item {
              width: calc(50% - 1.5rem);
            }
          }
        }
      }
    }
    .contents-section6 {
      .contents-container {
        height: 780px;
        .contents-box {
          &.inner {
            width: 1000px;
            margin: 0 auto;
          }
          h2 {
            font-size: 2.375rem;
            line-height: 3.375rem;
            margin-bottom: 4rem;
          }
          .btn {
            max-width: 440px;
            font-size: 1.625rem;
            line-height: 2rem;
            padding: 1.75rem 1.5rem;
          }
        }
      }
    }
    .contents-section7 {
      padding: 10rem 0 8.8125rem 0;
      .contents-container {
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        h2 {
          font-size: 1.875rem;
          line-height: 2.75rem;
        }
        .contents-list {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 1.5rem;
          .contents-item {
            width: calc(33.3% - 1.5rem);
          }
        }
      }
    }
    .contents-section8 {
      .contents-container {
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2.5rem;
          border-radius: 1rem;
          padding: 3.75rem 4rem 4.5rem 5rem;
          .contents-title {
            display: flex;
            align-items: center;
            gap: 1.9375rem;
            .contents-icon {
              margin-bottom: 0;
            }
            h2 {
              font-size: 1.75rem;
              line-height: 2.5rem;
              margin-bottom: 0;
            }
          }
          .btn-outline {
            font-size: 1.5rem;
            line-height: 1.5rem;
            border: 1px solid #db9e73;
          }
        }
      }
    }
    .contents-section9 {
      margin-bottom: 0;
      .contents-container {
        height: 344px;
        display: flex;
        align-items: center;
        .contents-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.inner {
            width: 1000px;
            margin: 0 auto;
          }
          h2 {
            font-size: 2.125rem;
            line-height: 3.125rem;
            margin-bottom: 0;
          }
          .btn-box {
            max-width: 440px;
            width: 100%;
            .btn {
              font-size: 1.625rem;
              line-height: 2rem;
              padding: 1.75rem;
            }
          }
        }
      }
    }
  }
  // 전립선
  #prostate {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        height: 400px;
        img {
          width: 100%;
          height: 400px;
        }
        .main-text {
          h2 {
            font-size: 3.5rem;
            font-weight: 700;
            line-height: 3.5rem;
            margin-bottom: 1.5rem;
          }
          .text {
            font-size: 2.75rem;
            font-weight: 400;
            line-height: 2.75rem;
          }
        }
      }
    }
    .contents-section1 {
      padding: 8.8125rem 0 14.75rem 0;
      &.contents-section-top {
        padding-top: 5rem;
        padding-bottom: 0;
        .contents-group {
          display: flex;
          justify-content: space-between;
          .contents-text-box {
            width: 50%;
          }
          .pc-contents-img {
            display: block;
          }
          .m-contents-img {
            display: none;
          }
        }
        .contents-text-top {
          .sub-title {
            margin-bottom: 2.5rem;
            h2 {
              font-size: 2.25rem;
              font-weight: 800;
              color: #dba075;
              line-height: 3.25rem;
              margin-bottom: 0;
            }
          }
          .sub-text {
            .text {
              font-size: var(--fs-20);
              font-weight: 500;
              color: #252525;
              line-height: 2.125rem;
              margin: 0;
            }
          }
        }
        .contents-text-bottom {
          padding-top: 6.375rem;
          .sub-title {
            margin-bottom: 3.625rem;
            h2 {
              font-size: 2.25rem;
              font-weight: 800;
              color: #dba075;
              line-height: 3.25rem;
              margin-bottom: 1.8125rem;
            }
            p {
              font-size: var(--fs-20);
              font-weight: 500;
              color: #252525;
              line-height: 2.125rem;
              margin: 0;
            }
          }
          .sub-text {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .sub-title-text {
              h2 {
                font-size: 1.25rem;
                font-weight: 700;
                color: #dba075;
                line-height: 2rem;
                margin-bottom: 0;
              }
              .text {
                font-size: var(--fs-18);
                font-weight: 500;
                color: #252525;
                line-height: 1.75rem;
                margin: 0;
              }
            }
          }
        }
      }
      .contents-container {
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-text {
          display: flex;
          justify-content: space-between;
          // gap: 6.25rem;
          padding: 0;
          margin-bottom: 8.5625rem;
          .sub-title {
            width: 50%;
            h2 {
              font-size: 2.25rem;
              line-height: 3.25rem;
              margin-bottom: 0;
            }
          }
          .sub-text {
            width: 50%;
            p {
              font-size: var(--fs-20);
              line-height: 2.125rem;
              margin: 0;
            }
            .link-text {
              font-size: var(--fs-16);
              line-height: 1.25rem;
              padding-top: 1.5rem;
            }
            .text-bottom {
              margin-bottom: 1.5rem;
            }
            &.text-box {
              display: flex;
              flex-direction: column;
              gap: 1.5rem;
            }
          }
        }
        .contents-item {
          padding: 0;
          margin-bottom: 0;
          .contents-img {
            display: none;
          }
          .contents-pc-img {
            display: block;
            padding: 10.6875rem 0 2.5rem 0;
            border-radius: 0.5rem;
            overflow: hidden;
          }
          p {
            font-size: var(--fs-20);
            line-height: 2.125rem;
            margin: 0;
          }
        }
      }
    }
    .contents-section2 {
      .contents-container {
        .contents-text {
          display: flex;
          justify-content: space-between;
          gap: 6.25rem;
          padding: 0;
          &.inner {
            width: 1000px;
            margin: 0 auto;
          }
          .sub-title {
            width: 50%;
            h2 {
              font-size: 2.25rem;
              line-height: 3.25rem;
              margin-bottom: 0;
            }
          }
          .sub-text {
            width: 50%;
            .text {
              font-size: var(--fs-20);
              line-height: 2.125rem;
              margin-bottom: 0;
            }
            &.text-box {
              display: flex;
              flex-direction: column;
              gap: 2rem;
            }
          }
        }
      }
    }
    .contents-section3 {
      padding: 17.6875rem 0 18.8125rem 0;
      .contents-container {
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-box {
          h2 {
            font-size: 2.25rem;
            line-height: 3.25rem;
            text-align: center;
          }
          .contents-list {
            flex-direction: row;
            .contents-item {
              width: calc(33.3% - 1.5rem);
              .contents-img {
                height: 216px;
                img {
                  height: 216px;
                }
              }
              .title {
                font-size: var(--fs-20);
                line-height: 1.5rem;
              }
              .info {
                font-size: var(--fs-18);
                line-height: 1.75rem;
              }
            }
          }
          .text {
            font-size: 1.625rem;
            line-height: 2.5rem;
            text-align: center;
            padding-top: 4.5rem;
          }
        }
      }
    }
    .contents-section4 {
      .contents-container {
        padding: 10rem 0 10.875rem 0;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        h2 {
          font-size: 2.25rem;
          line-height: 2.25rem;
          text-align: left;
        }
        .contents-list {
          display: flex;
          gap: 2rem 1.5rem;
          flex-wrap: wrap;
          .contents-item {
            width: calc(50% - 0.75rem);
            display: flex;
            flex-direction: column;
            padding-top: 0;
            .title {
              font-size: var(--fs-20);
              line-height: 1.25rem;
              margin-bottom: 1rem;
            }
            .contents-box {
              height: 100%;
            }
          }
        }
        .text {
          font-size: var(--fs-16);
          line-height: 1.125rem;
        }
      }
    }
    .contents-section5 {
      padding-top: 9.5rem;
      .contents-container {
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2.5rem;
          border-radius: 1rem;
          padding: 3.75rem 4rem 4.5rem 5rem;
          .contents-title {
            display: flex;
            align-items: center;
            gap: 1.9375rem;
            .contents-icon {
              margin-bottom: 0;
            }
            h2 {
              font-size: 1.75rem;
              line-height: 2.5rem;
              margin-bottom: 0;
            }
          }
          .btn-outline {
            font-size: 1.5rem;
            line-height: 1.5rem;
            border: 1px solid #db9e73;
          }
        }
      }
    }
    .contents-section6 {
      padding-top: 10.4375rem;
      margin-bottom: 0;
      .contents-container {
        height: 344px;
        display: flex;
        align-items: center;
        .contents-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2.5rem;
          &.inner {
            width: 1000px;
            margin: 0 auto;
          }
          h2 {
            font-size: 2.125rem;
            line-height: 3.125rem;
            margin-bottom: 0;
          }
          .btn-box {
            max-width: 440px;
            width: 100%;
            .btn {
              font-size: 1.625rem;
              line-height: 2rem;
              padding: 1.75rem;
            }
          }
        }
      }
    }
    .call-btn {
      width: 250px;
      height: 250px;
      bottom: 40px;
      right: 40px;
      // right: 109px;
      img {
        width: 47px;
        height: 47px;
      }
      p {
        font-size: 1.875rem;
        font-weight: 800;
        color: #fff;
        line-height: 2.5rem;
      }
    }
  }
  // 집중력
  #Focus {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        height: 400px;
        img {
          width: 100%;
          height: 400px;
        }
        .main-text {
          h2 {
            font-size: 3.5rem;
            font-weight: 700;
            line-height: 3.5rem;
            margin-bottom: 1.5rem;
          }
          .text {
            font-size: 2.75rem;
            font-weight: 400;
            line-height: 2.75rem;
          }
        }
      }
      .main-contents-text {
        display: flex;
        justify-content: space-between;
        gap: 6.25rem;
        padding: 5rem 0 7.4375rem 0;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .sub-title {
          width: 50%;
          h2 {
            font-size: 2.25rem;
            line-height: 3.25rem;
            margin-bottom: 0;
          }
        }
        .sub-text {
          width: 50%;
          display: flex;
          flex-direction: column;
          gap: 1.625rem;
          p {
            font-size: var(--fs-20);
            line-height: 2.125rem;
            margin: 0;
          }
        }
      }
    }
    .contents-section1 {
      margin-bottom: 9.4375rem;
      .contents-container {
        padding: 0;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-img {
          height: 400px;
          img {
            height: 400px;
          }
        }
        .contents-text {
          display: flex;
          justify-content: space-between;
          gap: 6.25rem;
          padding-top: 7.4375rem;
          .sub-title {
            width: 50%;
            h2 {
              font-size: 2.25rem;
              line-height: 3.25rem;
              margin-bottom: 0;
            }
          }
          .sub-text {
            width: 50%;
            display: flex;
            flex-direction: column;
            .text {
              margin-bottom: 2.3125rem;
            }
            .text-box {
              margin-bottom: 1.5rem;
              .index-text {
                gap: 1rem;
                li {
                  font-size: var(--fs-20);
                  font-weight: 500;
                  line-height: 1.25rem;
                }
              }
            }
            p {
              font-size: var(--fs-20);
              line-height: 2.125rem;
              margin: 0;
            }
          }
        }
      }
    }
    .contents-section2 {
      margin-bottom: 10.625rem;
      .contents-container {
        padding: 0;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-img {
          height: 400px;
          img {
            height: 400px;
          }
        }
        .contents-text {
          display: flex;
          justify-content: space-between;
          gap: 6.25rem;
          padding-top: 4.1875rem;
          .sub-title {
            width: 50%;
            h2 {
              font-size: 2.25rem;
              line-height: 3.25rem;
              margin-bottom: 0;
            }
          }
          .sub-text {
            width: 50%;
            p {
              font-size: var(--fs-20);
              line-height: 2.125rem;
              margin: 0;
            }
          }
        }
      }
    }
    .contents-section3 {
      margin-bottom: 14.3125rem;
      .contents-container {
        padding: 0;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-box {
          h2 {
            font-size: 2.25rem;
            line-height: 3.25rem;
            margin-bottom: 0;
          }
          .contents-list {
            flex-direction: row;
            gap: 1.5rem;
            padding-top: 2.375rem;
            .contents-item {
              width: calc(33.3% - 0.75rem);
              .contents-img {
                height: 216px;
                img {
                  height: 216px;
                }
              }
              .title {
                font-size: var(--fs-20);
                line-height: 1.5rem;
              }
              .info {
                font-size: var(--fs-18);
                line-height: 1.75rem;
              }
            }
          }
        }
      }
    }
    .contents-section4 {
      padding: 7.6875rem 0 8.625rem 0;
      margin-bottom: 0;
      .contents-container {
        padding: 0;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-text {
          margin-bottom: 3.1875rem;
          .contents-img {
            width: 100px;
            margin-bottom: 0.5rem;
          }
          h2 {
            font-size: 2.25rem;
            line-height: 3.125rem;
            margin-bottom: 2rem;
          }
          .text {
            font-size: 1.375rem;
            line-height: 1.875rem;
          }
        }
        .contents-box {
          h2 {
            font-size: 1.875rem;
            line-height: 2.75rem;
          }
          .contents-list {
            flex-direction: row;
            gap: 1.5rem;
            .contents-item {
              width: calc(33.3% - 0.75rem);
              .contents-img {
                height: 200px;
                img {
                  height: 200px;
                }
              }
            }
          }
        }
      }
    }
    .contents-section5 {
      padding-top: 9.5rem;
      .contents-container {
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2.5rem;
          border-radius: 1rem;
          padding: 3.75rem 4rem 4.5rem 5rem;
          .contents-title {
            display: flex;
            align-items: center;
            gap: 1.9375rem;
            .contents-icon {
              margin-bottom: 0;
            }
            h2 {
              font-size: 1.75rem;
              line-height: 2.5rem;
              margin-bottom: 0;
            }
          }
          .btn-outline {
            font-size: 1.5rem;
            line-height: 1.5rem;
            border: 1px solid #db9e73;
          }
        }
      }
    }
    .contents-section6 {
      padding-top: 10.4375rem;
      margin-bottom: 0;
      .contents-container {
        height: 344px;
        display: flex;
        align-items: center;
        .contents-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2.5rem;
          &.inner {
            width: 1000px;
            margin: 0 auto;
          }
          h2 {
            font-size: 2.125rem;
            line-height: 3.125rem;
            margin-bottom: 0;
          }
          .btn-box {
            max-width: 440px;
            width: 100%;
            .btn {
              font-size: 1.625rem;
              line-height: 2rem;
              padding: 1.75rem;
            }
          }
        }
      }
    }
  }
  // 다이어트
  #diet {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        height: 400px;
        img {
          width: 100%;
          height: 400px;
        }
        .main-text {
          h2 {
            font-size: 3.5rem;
            font-weight: 700;
            line-height: 3.5rem;
            margin-bottom: 1.5rem;
          }
          .text {
            font-size: 2.75rem;
            font-weight: 400;
            line-height: 2.75rem;
          }
        }
      }
      .main-contents-text {
        padding: 9.75rem 2.5625rem 10.6875rem 2.5625rem;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
      }
    }
    .contents-section1 {
      margin-bottom: 4.5625rem;
      .contents-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5.8125rem 0 6.8125rem 0;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-box1 {
          margin-bottom: 0;
        }
        .contents-box2 {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          .info-box {
            p {
              font-size: var(--fs-16);
              line-height: 1.5rem;
            }
          }
        }
      }
    }
    .contents-section2 {
      margin-bottom: 10.1875rem;
      .contents-container {
        padding: 0;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-group {
          flex-direction: row;
          justify-content: space-between;
          gap: 16.875rem;
          .contents-box {
            h2 {
              font-size: 2.25rem;
              line-height: 3.25rem;
              margin-bottom: 1.5rem;
            }
          }
        }
        .contents-detail {
          padding-top: 6.25rem;
          h2 {
            font-size: 2.25rem;
            line-height: 3.25rem;
            text-align: center;
            margin-bottom: 3.3125rem;
          }
          .btn-box {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    .contents-section3 {
      // padding: 5rem 0 7rem 0;
      .contents-container {
        height: 700px;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        img {
          width: 100%;
          height: 700px;
          object-fit: cover;
        }
      }
    }
    .contents-section4 {
      padding: 7.5rem 0;
      .contents-container {
        padding: 0;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-box {
          display: flex;
          justify-content: space-between;
          gap: 5.5rem;
          h2 {
            min-width: 320px;
            font-size: 2.25rem;
            line-height: 3.25rem;
          }
          .contents-group {
            flex-direction: row;
            flex-wrap: wrap;
            column-gap: 24px;
            row-gap: 28px;
            justify-content: flex-end;
            .contents-item {
              .contents-item-title {
                p {
                  font-size: var(--fs-16);
                  font-weight: 1rem;
                }
              }
            }
          }
        }
      }
    }
    .contents-section5 {
      padding-bottom: 15.6875rem;
      .contents-container {
        padding: 0;
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-box {
          .top-box {
            margin-bottom: 1rem;
          }
          .title {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }
          .title-text {
            .title-detail-text {
              display: flex;
              align-items: center;
              gap: 0.25rem;
            }
          }
          .img-group {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 0;
            .img-item {
              .m-gold-img {
                display: none;
              }
              .gold-img {
                display: block;
              }
            }
          }
          .btn-box {
            padding-top: 3.25rem;
            .btn {
              font-size: 1.625rem;
              padding: 1.75rem 0;
              margin: 0 auto;
            }
          }
          .sub-title {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-bottom: 1.5rem;
          }
          .sub-list {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 1.5rem;
            margin-bottom: 1.5rem;
            .sub-item {
              width: calc(33.3% - 1rem);
            }
          }
          .sub-info-box {
            display: flex;
            align-items: center;
            gap: 0.25rem;
          }
          .sub-group {
            padding-top: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .sub-group-list {
              width: calc(33.3% - 1rem);
              .sub-group-box {
                min-height: 272px;
              }
            }
          }
        }
      }
    }
    .contents-section6 {
      margin-bottom: 0;
      .contents-container {
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-box {
          &.contents-1 {
            padding-top: 2.5rem;
          }
          &.contents-2 {
            padding-top: 7.25rem;
          }
          &.contents-3 {
            padding-top: 7.25rem;
          }
          .contents-top {
            padding: 0;
            margin-bottom: 2.25rem;
            .top-box {
              &.top-sub-box {
                align-items: center;
                .number {
                  margin-top: 0;
                }
                .top-sub-title {
                  display: flex;
                  gap: 0.5rem;
                }
              }
            }
          }
          .contents-img-box {
            display: flex;
            gap: 1.5rem;
            .contents-img {
              min-height: 520px;
              max-height: 520px;
              border-radius: 1rem;
              overflow: hidden;
              img {
                min-height: 520px;
                max-height: 520px;
                object-fit: cover;
              }
            }
            .sub-group {
              max-width: 384px;
              max-height: 520px;
              margin: 0;
              .vertical-line {
                margin: 1rem 0.25rem 1rem 1rem;
              }
              .detail-sub-text {
                padding: 0 0.25rem 0 1rem;
              }
              .sub-text {
                font-size: var(--fs-16);
                line-height: 1.625rem;
                &.sub-text-normal {
                  font-size: var(--fs-14);
                  line-height: 1.5rem;
                }
                &.special-text {
                  margin-bottom: 1rem;
                }
              }
            }
          }
        }
      }
    }
    .contents-section7 {
      padding-top: 7.5rem;
      .contents-container {
        &.inner {
          width: 1000px;
          margin: 0 auto;
        }
        .contents-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2.5rem;
          border-radius: 1rem;
          padding: 3.75rem 4rem 4.5rem 5rem;
          .contents-title {
            display: flex;
            align-items: center;
            gap: 1.9375rem;
            .contents-icon {
              margin-bottom: 0;
            }
            .contents-title-text {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              margin-bottom: 0;
            }
            h2 {
              font-size: 1.75rem;
              line-height: 2.5rem;
              margin-bottom: 0;
            }
          }
          .btn-outline {
            font-size: 1.5rem;
            line-height: 1.5rem;
            border: 1px solid #db9e73;
            padding: 1.75rem 0;
          }
        }
      }
    }
    .contents-section8 {
      padding-top: 10rem;
      margin-bottom: 0;
      .contents-container {
        height: 344px;
        display: flex;
        align-items: center;
        .contents-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 2.5rem;
          &.inner {
            width: 1000px;
            margin: 0 auto;
          }
          .contents-title-box {
            margin-bottom: 0;
            .contents-title-text {
              display: flex;
              align-items: center;
              gap: 0.5rem;
            }
          }
          h2 {
            font-size: 2.125rem;
            line-height: 3.125rem;
            margin-bottom: 0;
            text-align: left;
          }
          .btn-box {
            max-width: 440px;
            width: 100%;
            .btn {
              font-size: 1.625rem;
              line-height: 2rem;
              padding: 1.75rem;
            }
          }
        }
      }
    }
  }
  // 상담 예약
  #review {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        max-height: 400px;
        img {
          width: 100%;
          max-height: 400px;
        }
      }
    }
    .contents-section {
      padding-top: 7.5rem;
      .contents-container {
        .contents-box {
          h2 {
            font-size: 2.5rem;
            line-height: 3.5rem;
          }
          .text {
            font-size: var(--fs-20);
            font-weight: 500;
            line-height: 2rem;
            margin-bottom: 5rem;
          }
          .contents-tab {
            margin-bottom: 2.5rem;
            li {
              font-size: var(--fs-16);
              font-weight: 600;
              line-height: 1rem;
              padding: 1rem;
            }
          }
          .contents-list {
            gap: 2.5rem 1.5rem;
            .contents-item {
              width: calc((100% - 3rem) / 3);
              .contents-img {
                height: 214px;
                margin-bottom: 1.5rem;
                img {
                  width: 100%;
                  height: 214px;
                }
              }
              .tab {
                font-size: var(--fs-16);
                font-weight: 500;
                line-height: 1rem;
                padding: 0.5rem 1.125rem;
                margin-bottom: 1rem;
              }
              .title {
                font-size: var(--fs-20);
                font-weight: 600;
                line-height: 2rem;
              }
              .day {
                font-size: var(--fs-16);
                font-weight: 500;
                line-height: 1rem;
              }
            }
          }
          .page-container {
            padding-top: 6.4375rem;
          }
        }
      }
    }
  }
  // 치료 후기 상세
  #review-detail {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        max-height: 400px;
        img {
          width: 100%;
          max-height: 400px;
        }
      }
    }
    .contents-section {
      padding: 2.5rem 0 12.5rem 0;
      &.inner {
        width: 1000px;
        margin: 0 auto;
        .contents-container {
          .contents-box {
            h2 {
              font-size: 2.5rem;
              line-height: 3.5rem;
            }
            .text {
              font-size: var(--fs-20);
              font-weight: 500;
              line-height: 2rem;
            }
            .contents-title {
              display: flex;
              align-items: center;
              gap: 1.5rem;
              .tab {
                font-size: var(--fs-16);
                font-weight: 500;
                line-height: 1rem;
                padding: 0.5rem 1.125rem;
                margin: 0;
              }
              .title {
                font-size: var(--fs-24);
                font-weight: 700;
                line-height: 2.25rem;
                margin: 0;
              }
            }
            .contents-day {
              gap: 1.5rem;
              .day {
                .icon {
                  width: 15px;
                  height: 15px;
                }
                p {
                  font-size: var(--fs-16);
                  font-weight: 500;
                  line-height: 0.75rem;
                }
              }
            }
            .contents-info {
              padding: 1.5rem 0;
              margin-bottom: 5rem;
              border-bottom: 1px solid #bfbfbf;
              p {
                font-size: var(--fs-20);
                line-height: 2rem;
              }
            }
            .btn-outline {
              font-size: var(--fs-20);
              line-height: 2rem;
              border-radius: 0.5rem;
            }
          }
        }
      }
    }
  }
  // 공지사항
  #notices {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        max-height: 400px;
        img {
          width: 100%;
          max-height: 400px;
        }
      }
    }
    section {
      &.inner {
        width: 1000px;
        margin: 0 auto;
      }
    }
    .contents-section1 {
      padding: 7.5rem 0 0 0;
      .contents-container {
        .contents-list {
          h2 {
            font-size: 2.5rem;
            line-height: 3.5rem;
          }
          .text {
            font-size: var(--fs-20);
            font-weight: 500;
            line-height: 2rem;
          }
          .contents-fix {
            .contents-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .title {
                font-size: var(--fs-24);
                font-weight: 700;
                line-height: 2.25rem;
                margin: 0;
              }
              .title-box {
                display: flex;
                align-items: center;
                gap: 2rem;
                .day {
                  font-size: var(--fs-16);
                  font-weight: 500;
                  line-height: 0.75rem;
                }
                .icon {
                  width: 20px;
                  height: 20px;
                  position: static;
                }
              }
            }
          }
        }
      }
    }
    .contents-section2 {
      padding: 0;
      .contents-container {
        .contents-list {
          .contents-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title {
              font-size: var(--fs-24);
              font-weight: 700;
              line-height: 2.25rem;
              margin: 0;
            }
            .day {
              font-size: var(--fs-16);
              font-weight: 500;
              line-height: 0.75rem;
            }
          }
        }
      }
    }
  }
  // 공지사항 상세
  #noticeDetail {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        max-height: 400px;
        img {
          width: 100%;
          max-height: 400px;
        }
      }
    }
    .contents-section {
      padding: 7.5rem 0 12.5rem 0;
      &.inner {
        width: 1000px;
        margin: 0 auto;
        .contents-container {
          .contents-box {
            h2 {
              font-size: 2.5rem;
              line-height: 3.5rem;
            }
            .text {
              font-size: var(--fs-20);
              font-weight: 500;
              line-height: 2rem;
            }
            .contents-title {
              .title {
                font-size: var(--fs-24);
                font-weight: 700;
                line-height: 2.25rem;
                margin: 0;
              }
            }
            .contents-day {
              .day {
                .icon {
                  width: 15px;
                  height: 15px;
                }
                p {
                  font-size: var(--fs-16);
                  font-weight: 500;
                  line-height: 0.75rem;
                }
              }
            }
            .contents-text {
              font-size: var(--fs-20);
              line-height: 2rem;
            }
          }
        }
      }
    }
  }
  // 자주 묻는 질문
  #faqs {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        max-height: 400px;
        img {
          width: 100%;
          max-height: 400px;
        }
      }
    }
    .contents-section {
      &.inner {
        width: 1000px;
        margin: 0 auto;
        .contents-container {
          .contents-list {
            padding: 7.5rem 0 12.5rem 0;
            h2 {
              font-size: 2.5rem;
              line-height: 3.5rem;
            }
            .contents-item {
              .title {
                .info {
                  font-size: var(--fs-24);
                  font-weight: 700;
                  line-height: 2.25rem;
                }
              }
              .info-detail {
                font-size: var(--fs-18);
                font-weight: 500;
                line-height: 1.875rem;
              }
            }
          }
        }
      }
    }
  }
  // 상담 예약
  #reservations {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        max-height: 400px;
        img {
          width: 100%;
          max-height: 400px;
        }
      }
    }
    .contents-section {
      .contents-container {
        padding-top: 7.5rem;
        .contents-list {
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          .contents-title {
            width: 50%;
            h2 {
              font-size: 2.5rem;
              line-height: 3.5rem;
              padding-top: 0;
            }
          }
          .contents-item {
            width: 50%;
            .agree {
              .agree-title {
                p {
                  font-size: var(--fs-20);
                  font-weight: 500;
                  line-height: 2rem;
                }
              }
            }
            .contents-info {
              .text-box {
                .sub-box {
                  li {
                    font-size: var(--fs-14);
                    line-height: 1.25rem;
                  }
                }
                .title {
                  font-size: var(--fs-16);
                  line-height: 1.75rem;
                }
                .text {
                  font-size: var(--fs-16);
                  line-height: 1.75rem;
                }
                .detail {
                  p {
                    font-size: var(--fs-16);
                    line-height: 1.75rem;
                  }
                  li {
                    font-size: var(--fs-16);
                    line-height: 1.75rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // 상담 예약 상세
  #reservations-write {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        max-height: 400px;
        img {
          width: 100%;
          max-height: 400px;
        }
      }
    }
    .contents-section {
      .contents-container {
        padding-top: 7.5rem;
        .contents-list {
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          .contents-title {
            width: 50%;
            h2 {
              font-size: 2.5rem;
              line-height: 3.5rem;
              padding-top: 0;
            }
            p {
              font-size: var(--fs-20);
              font-weight: 500;
              line-height: 2rem;
            }
          }
          .contents-item {
            width: 50%;
            .input-box {
              .sub-input {
                .select {
                  width: 40%;
                }
              }
            }
            .btn-box {
              button {
                font-size: 1.375rem;
                font-weight: 700;
                line-height: 2rem;
              }
            }
          }
        }
      }
    }
  }
  // 상담 예약 성공
  #reservations-clear {
    .main-section {
      padding-top: 7.5rem;
      .main-img {
        max-height: 400px;
        img {
          width: 100%;
          max-height: 400px;
        }
      }
    }
    .contents-section {
      .contents-container {
        .contents-list {
          width: 100%;
          flex-direction: row;
          justify-content: center;
          gap: 4rem;
          padding-bottom: 11.625rem;
          .contents-img {
            width: 200px;
            height: 200px;
          }
          .contents-title {
            text-align: left;
            h2 {
              font-size: 2.5rem;
              line-height: 3.5rem;
              padding-top: 0;
            }
            p {
              font-size: var(--fs-20);
              font-weight: 500;
              line-height: 2rem;
              span {
                padding-left: 0.5rem;
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
  // 푸터
  #footer {
    height: 240px;
    display: flex;
    align-items: center;
    .footer-container {
      padding: 0;
      &.inner {
        width: 1000px;
        margin: 0 auto;
        .footer-top {
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .footer-title {
            display: flex;
            .logo {
              margin-bottom: 0;
            }
            .info-box {
              padding-left: 2.5rem;
              .info {
                display: flex;
                flex-wrap: wrap;
                .responsive-span {
                  display: block;
                  padding: 0 0.625rem;
                }
              }
              .responsive-box {
                display: flex;
                align-items: center;
                gap: 1.5rem;
                .icon-box {
                  padding-top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

// ================== min-width: 1200px 이상 ==================
@media (min-width: 1200px) {
  // 헤더
  #header {
    &.header-home {
      .header-container {
        position: relative;
        &.inner {
          width: 1200px;
        }
      }
    }
    .header-container {
      &.inner {
        width: 1200px;
        .header-nav {
          .header-list {
            .sub-item {
              padding: 0 2rem;
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
            }
            .sub-header-list {
              gap: 2rem;
            }
          }
        }
      }
    }
  }
  // 랜딩
  #landing {
    .landing-section {
      .landing-container {
        &.inner {
          width: 1200px;
          .landing-contact {
            .contact-list {
              gap: 1.5rem;
            }
            .contact-options {
              .contact-option {
                padding: 1.5rem 4.5rem;
              }
            }
          }
        }
      }
    }
  }
  // 메인 페이지
  #home {
    .home-container {
      section {
        &.inner {
          width: 1200px;
        }
        &.main-section {
          .main-container {
            .main-contents {
              &.inner {
                width: 1200px;
              }
            }
          }
        }
        &.contents-section2 {
          .contents-container {
            .contents-box {
              &.inner {
                width: 1200px;
              }
            }
          }
        }
      }
    }
  }
  // 한의원 소개
  #introduction {
    .contents-box {
      &.inner {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
  // 의료진 소개
  #doctors {
    .contents-box {
      &.inner {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
  // 지점 안내
  #directions {
    .contents-section {
      .contents-container {
        .contents-box {
          &.inner {
            width: 1200px;
            margin: 0 auto;
          }
        }
        .contents-list {
          &.contents-3 {
            .contents-container {
              &.inner {
                width: 1200px;
                margin: 0 auto;
              }
            }
          }
        }
        .map {
          &.inner {
            width: 1200px;
          }
        }
      }
    }
  }
  // 키성장
  #growth {
    .contents-section1 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section2 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section3 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section5 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
        .contents-box {
          // .title {
          //   width: 20%;
          // }
          .contents-list {
            width: 80%;
            .contents-item {
              width: calc(33% - 1.5rem);
            }
          }
        }
      }
    }
    .contents-section6 {
      .contents-container {
        .contents-box {
          &.inner {
            width: 1200px;
          }
        }
      }
    }
    .contents-section7 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section8 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section9 {
      .contents-container {
        .contents-box {
          &.inner {
            width: 1200px;
          }
        }
      }
    }
  }
  // 전립선
  #prostate {
    .contents-section1 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section2 {
      .contents-container {
        .contents-text {
          &.inner {
            width: 1200px;
          }
        }
      }
    }
    .contents-section3 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section4 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section5 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section6 {
      .contents-container {
        .contents-box {
          &.inner {
            width: 1200px;
          }
        }
      }
    }
  }
  // 집중력
  #Focus {
    .main-section {
      .main-contents-text {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section1 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section2 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section3 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section4 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section5 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section6 {
      .contents-container {
        .contents-box {
          &.inner {
            width: 1200px;
          }
        }
      }
    }
  }
  // 다이어트
  #diet {
    .main-section {
      .main-contents-text {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section1 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section2 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section3 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section4 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section5 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section6 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section7 {
      .contents-container {
        &.inner {
          width: 1200px;
        }
      }
    }
    .contents-section8 {
      .contents-container {
        .contents-box {
          &.inner {
            width: 1200px;
          }
        }
      }
    }
  }
  // 치료 후기
  #review {
    .contents-section {
      &.inner {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
  // 치료 후기 상세
  #review-detail {
    .contents-section {
      &.inner {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
  // 공지사항
  #notices {
    section {
      &.inner {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
  // 공지사항 상세
  #noticeDetail {
    .contents-section {
      &.inner {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
  // 자주 묻는 질문
  #faqs {
    .contents-section {
      &.inner {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
  // 상담 예약
  #reservations {
    .contents-section {
      &.inner {
        width: 1200px;
        margin: 0 auto;
        .contents-container {
          min-height: 642px;
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  // 상담 예약 상세
  #reservations-write {
    .contents-section {
      &.inner {
        width: 1200px;
        margin: 0 auto;
        .contents-container {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  // 상담 예약 성공
  #reservations-clear {
    .contents-section {
      &.inner {
        width: 1200px;
        margin: 0 auto;
        .contents-container {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }
  }
  #private {
    .main-section {
      padding-top: 7.5rem;
      &.inner {
        width: 1200px;
        margin: 0 auto;
      }
    }
  }
  // 푸터
  #footer {
    .footer-container {
      &.inner {
        width: 1200px;
      }
    }
  }
}

// ================== min-width: 1400px 이상 ==================
@media (min-width: 1400px) {
  // 헤더
  #header {
    .header-container {
      &.inner {
        width: 1400px;
        .header-nav {
          .header-list {
            .sub-item {
              padding: 0 4rem;
              .menu {
                ul {
                  &.inner {
                    width: 1400px;
                    padding-left: 16.25rem;
                  }
                  &.inner-right {
                    width: 1400px;
                    padding-right: 21.25rem;
                  }
                }
              }
            }
            .sub-header-list {
              gap: 4rem;
            }
          }
        }
      }
    }
    // 헤더 스크롤 되었을 때
    // &.scrolled {
    //   .header-container {
    //     &.inner {
    //       .header-nav {
    //         ul {
    //           gap: 4rem;
    //         }
    //       }
    //     }
    //   }
    // }
  }
  // #diet {
  //   .contents-section6 {
  //     .contents-container {
  //       &.inner {
  //         width: 1400px;
  //         margin: 0 auto;
  //       }
  //     }
  //   }
  // }
}
