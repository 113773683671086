// common.scss
* {
  font-family: var(--bs-body-font-family);
}
html {
  font-size: 16px; // rem 기준
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--bs-dark);
  /* Prevent font scaling in landscape */
  -webkit-text-size-adjust: none; /*Chrome, Safari, newer versions of Opera*/
  -moz-text-size-adjust: none; /*Firefox*/
  -ms-text-size-adjust: none; /*Ie*/
  -o-text-size-adjust: none; /*old versions of Opera*/
}
a,
a:hover {
  text-decoration: none;
}
ul,
ol {
  padding-left: 0;
  margin: 0;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}
li {
  list-style: none;
}
button {
  border: 0;
  outline: 0;
  background: transparent;
  padding: 8px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  max-width: 100%;
}
h2 {
  word-break: keep-all;
  white-space: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.5;
}
p,
figure,
dl,
dd {
  margin: 0;
}
::selection {
  background: rgb(205, 223, 243);
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 8px 8px var(--bs-ts);
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 8px 8px var(--bs-gray-500);
  border: solid 2px transparent;
}
::-webkit-scrollbar-thumb:hover {
  box-shadow: inset 0 0 8px 8px var(--bs-gray-600);
}

// ----------------------------------------------------------------------------
// --------------------------------- bootstrap --------------------------------
// ----------------------------------------------------------------------------
// container
.container-fluid {
  max-width: 100%;
  padding: 0;
}
.container {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 1080px;
  padding: 2rem;
  margin: 0 auto;
  &.container-m15 {
    min-width: 1500px;
  }
  &.container-110 {
    min-width: 1100px;
  }
  &.container-135 {
    min-width: 1350px;
  }
  &.container-200 {
    min-width: 1700px;
  }
}
.container-xs {
  max-width: 400px;
  padding: 0;
}
.container-sm {
  max-width: 600px;
  padding: 0;
}
.container-md {
  max-width: 1024px;
  padding: 0;
}
.container-xl {
  max-width: 1420px;
  padding: 0;
}
// row
.row > * {
  min-width: 1px;
}
// pagination
.page-container {
  padding-top: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .page-btn {
    width: 32px;
    height: 32px;
    display: flex;
    padding: 0.5625rem;
    cursor: pointer;
  }
  .pagination {
    .page-item {
      &.active {
        background-color: #dba075;
        border-radius: 0.25rem;
        .page-link {
          color: #fff;
        }
      }
      &.focus {
        background-color: #dba075;
        border-radius: 0.25rem;
        .page-link {
          color: #fff;
        }
      }
      .page-link {
        width: 32px;
        background-color: transparent;
        border: none;
        font-size: var(--fs-14);
        font-weight: 700;
        color: #252525;
        line-height: 0.875rem;
        text-align: center;
        padding: 0.5625rem;
        &.prev {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          padding: 0.5625rem;
        }
        &.next {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          padding: 0.5625rem;
        }
      }
    }
  }
}
// top-btn
.top-btn {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
  z-index: 10;
  cursor: pointer;
  &.hovered {
    background-color: #dba075;
  }
  img {
    width: 18px;
    height: 24px;
  }
}
// call-btn
.call-btn {
  width: 250px;
  height: 250px;
  background-color: #355955;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18.88px;
  box-shadow: 10px 10px 16px rgba(0, 0, 0, 0.2);
  bottom: 40px;
  right: 109px;
  cursor: pointer;
  img {
    width: 47px;
    height: 47px;
  }
  p {
    font-size: 1.875rem;
    font-weight: 800;
    color: #fff;
    line-height: 2.5rem;
  }
}

// btn
.btn {
  width: auto;
  // flex-shrink: 0;
  font-weight: 700;
  font-size: var(--fs-18);
  padding: 0.5rem 1.5rem;
  border-radius: 24px;
  line-height: 32px;
  letter-spacing: 0.16px;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-shadow-rgb), 0.2);
  --bs-btn-active-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-shadow-rgb), 0.5);
  // state
  &:focus {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    box-shadow: var(--bs-btn-focus-box-shadow);
    outline: 0;
  }
  &:active {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color);
    box-shadow: var(--bs-btn-active-box-shadow);
    outline: 0;
  }
  &:disabled {
    pointer-events: none;
    opacity: 1;
    color: var(--bs-btn-disabled-color);
    background-color: #b1b3c7;
    border-color: #b1b3c7;
  }
  // size
  &.btn-sm {
    font-size: var(--fs-16) !important;
    padding: 0.25rem 1rem;
  }
  &.btn-lg {
    font-size: var(--fs-18);
    padding: 0.5rem 1.5rem;
  }
  // variant
  &.btn-underline {
    padding: 0;
    border: 0;
    width: auto;
    text-align: left;
    text-decoration: underline;
    color: var(--bs-primary) !important;
    font-weight: 500;
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: 0 !important;
    }
  }
  &.btn-text-primary {
    padding: 0;
    border: 0;
    width: auto;
    text-align: left;
    color: var(--bs-primary) !important;
    font-weight: 500;
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }
  &.btn-text-dark {
    padding: 0;
    border: 0;
    width: auto;
    text-align: left;
    color: var(--bs-dark) !important;
    font-weight: 500;
    &:focus,
    &:active {
      box-shadow: none !important;
      outline: none !important;
    }
  }
  &.btn-icon {
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    i {
      transition: 0.2s ease-in-out;
    }
  }
  &.btn-icon-text {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 0;
    i {
      transition: 0.2s ease-in-out;
      & + * {
        margin-left: 0.5rem;
      }
    }
  }
  &.btn-primary {
    --bs-btn-bg: #dba075;
    --bs-btn-border-color: #dba075;
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-bg: transparent;
    --bs-btn-hover-border-color: #dba075;
    --bs-btn-hover-color: #dba075;
    --bs-btn-focus-bg: #fff;
    --bs-btn-focus-border-color: #dba075;
    --bs-btn-focus-color: #dba075;
    --bs-btn-active-bg: transparent;
    --bs-btn-active-border-color: #dba075;
    --bs-btn-active-color: #dba075;
    --bs-btn-disabled-bg: var(--bs-disabled-back);
    --bs-btn-disabled-border-color: var(--bs-disabled-back);
    --bs-btn-disabled-color: var(--bs-disabled-color);
    --bs-btn-shadow-rgb: var(--bs-primary-rgb);
  }
  &.btn-outline-primary {
    --bs-btn-bg: var(--bs-ts);
    --bs-btn-border-color: #dba075;
    --bs-btn-color: #dba075;
    --bs-btn-hover-bg: #dba075;
    --bs-btn-hover-border-color: #dba075;
    --bs-btn-hover-color: #fff;
    --bs-btn-focus-bg: #dba075;
    --bs-btn-focus-border-color: #dba075;
    --bs-btn-focus-color: #fff;
    --bs-btn-active-bg: #dba075;
    --bs-btn-active-border-color: #dba075;
    --bs-btn-active-color: #fff;
    --bs-btn-disabled-bg: var(--bs-ts);
    --bs-btn-disabled-border-color: #387fff;
    --bs-btn-disabled-color: var(--bs-disabled-pri);
    --bs-btn-shadow-rgb: var(--bs-primary-rgb);
  }
  &.btn-ts {
    --bs-btn-bg: var(--bs-ts);
    --bs-btn-border-color: var(--bs-ts);
    --bs-btn-color: var(--bs-dark);
    --bs-btn-hover-bg: rgba(var(--bs-primary-rgb), 0.08);
    --bs-btn-hover-border-color: var(--bs-ts);
    --bs-btn-hover-color: var(--bs-dark);
    --bs-btn-focus-bg: rgba(var(--bs-primary-rgb), 0.12);
    --bs-btn-focus-border-color: var(--bs-ts);
    --bs-btn-focus-color: var(--bs-dark);
    --bs-btn-active-bg: rgba(var(--bs-primary-rgb), 0.12);
    --bs-btn-active-border-color: var(--bs-ts);
    --bs-btn-active-color: var(--bs-dark);
    --bs-btn-disabled-bg: var(--bs-ts);
    --bs-btn-disabled-border-color: var(--bs-ts);
    --bs-btn-disabled-color: var(--bs-gray-600);
    --bs-btn-shadow-rgb: var(--bs-gray-rgb);
  }
}
// header
#header {
  width: 100%;
  height: 120px;
  position: fixed;
  background-color: #fff;
  z-index: 9;
  transition: background-color 0.3s;
  // 랜딩 헤더
  &.header-landing {
    background-color: transparent;
  }
  // 메인 헤더
  &.header-home {
    background-color: transparent;
    .header-container {
      width: 100%;
      height: 100%;
      .header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        .home-logo {
          padding-left: 1.5rem;
          cursor: pointer;
          img {
            width: 105px;
          }
        }
        .nav-fixed {
          display: none;
        }
        .header-box {
          display: flex;
          align-items: center;
          padding-right: 1.5rem;
          .btn-outline-primary {
            display: none;
          }
          .btn-mobile {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 30px;
            height: 25px;
            margin-left: 1.25rem;
            background: transparent;
            border: none;
            cursor: pointer;
            z-index: 2000;
            .menu-line {
              height: 2px;
              width: 100%;
              background-color: #db9e73;
              transition: all 0.3s ease;
            }
            // '.open' 클래스가 적용됐을 때의 스타일
            &.open {
              .menu-line {
                &:nth-child(1) {
                  transform: translateY(8px) rotate(45deg);
                  background-color: #000;
                }
                &:nth-child(2) {
                  opacity: 0;
                }
                &:nth-child(3) {
                  transform: translateY(-8px) rotate(-45deg);
                  background-color: #000;
                }
              }
            }
          }
        }
      }
      .header-bottom {
        display: none;
      }
    }
  }
  &.scrolled {
    background-color: #fff;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  }
  .header-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      padding-left: 1.5rem;
      cursor: pointer;
      img {
        width: 105px;
      }
    }
    .header-nav {
      display: none;
    }
    .header-box {
      display: flex;
      align-items: center;
      padding-right: 1.5rem;
      .btn-outline-primary {
        display: none;
      }
      .btn-mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 30px;
        height: 25px;
        margin-left: 1.25rem;
        background: transparent;
        border: none;
        cursor: pointer;
        z-index: 2000;
        .menu-line {
          height: 2px;
          width: 100%;
          background-color: #db9e73;
          transition: all 0.3s ease;
        }
        // '.open' 클래스가 적용됐을 때의 스타일
        &.open {
          .menu-line {
            &:nth-child(1) {
              transform: translateY(8px) rotate(45deg);
              background-color: #dba075;
            }
            &:nth-child(2) {
              opacity: 0;
            }
            &:nth-child(3) {
              transform: translateY(-8px) rotate(-45deg);
              background-color: #dba075;
            }
          }
        }
      }
    }
    // 모바일 메뉴
    .mobile-tab {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background-color: #fff;
      z-index: 1000;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      overflow: hidden;
      height: 0;
      transition: height 0.5s ease-out;
      .menu {
        padding: 6.75rem 2.5rem 0 2.5rem;
        .menu-item {
          font-size: 1.5rem;
          font-weight: 700;
          color: #dba075;
          line-height: 1.5rem;
          border-bottom: 1px solid #dba075;
          padding: 0 0 1.5rem 0.625rem;
          margin-bottom: 1.5rem;
          .menu-title {
            display: inline-block;
            .menu-title-box {
              display: flex;
              align-items: center;
              color: #dba075;
              cursor: pointer;
              .menu-icon {
                display: flex;
                align-items: center;
                width: 16px;
                .rotate-icon {
                  // transition: transform 0.1s ease;
                  // width: 12px;
                  // height: 8px;
                  margin-left: 1rem;
                }
                .rotate-icon.open {
                  transform: rotate(-180deg);
                }
              }
            }
          }
        }
        .submenu {
          padding-top: 2rem;
          padding-left: 1rem;
          li {
            font-size: 1.375rem;
            font-weight: 500;
            line-height: 1.375rem;
            margin-bottom: 1rem;
          }
        }
      }
    }
    /* 모바일 탭 창이 보일 때 */
    .mobile-tab.visible {
      height: 100%;
      overflow-y: scroll;
      transition: height 0.5s ease-out;
    }
  }
}
// footer
#footer {
  border-top: 1px solid #5a5a5a;
  background-color: #fff;
  .footer-container {
    padding: 2.5rem 1.5rem 3.125rem 1.5rem;
    .footer-top {
      .logo {
        width: 105px;
        margin-bottom: 1.5rem;
      }
      .info-box {
        .info {
          margin-bottom: 1rem;
          li {
            font-size: var(--fs-14);
            font-weight: 400;
            color: #5a5a5a;
            line-height: 1.5rem;
            span {
              padding: 0 0.625rem;
            }
          }
          .responsive-span {
            display: none;
          }
        }
        .responsive-box {
          .btn-outline-primary {
            font-size: var(--fs-12);
            font-weight: 500;
            color: #5a5a5a;
            line-height: 0.875rem;
            padding: 0.3125rem 0.625rem;
            border: 1px solid #5a5a5a;
            border-radius: 0.25rem;
          }
          .icon-box {
            display: flex;
            gap: 0 0.75rem;
            padding-top: 1.5rem;
            li {
              width: 24px;
              height: 24px;
              cursor: pointer;
            }
          }
        }
      }
    }
    .footer-bottom {
      padding-top: 1.5rem;
      p {
        font-size: var(--fs-12);
        font-weight: 400;
        color: #5a5a5a;
        line-height: 1.125rem;
      }
    }
  }
}
// form
.form-control {
  width: 18.875rem;
  height: 48px;
  padding: 0.875rem 1rem;
  padding: 0.6rem 0.8rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border-color: var(--bs-border);
  font-weight: 400;
  color: var(--bs-dark);
  resize: none;
  &::placeholder {
    color: #a8a8a8;
    font-weight: 400;
  }
  &:focus {
    border-color: var(--bs-primary);
    box-shadow: 0px 0px 0px 4px rgba(var(--bs-primary-rgb), 0.2);
  }
  &:disabled {
    background-color: var(--bs-border);
  }
  &[readonly] {
    padding: 0.65rem 0;
    background-color: transparent;
    border-color: transparent;
    cursor: inherit;
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
  &.mw-280 {
    max-width: 280px;
  }
  &.w-100 {
    width: 100%;
  }
  &.max-100 {
    max-width: 100%;
  }
  &.mw-200 {
    max-width: 200px;
  }
}
small,
.form-text {
  color: var(--bs-gray);
  font-size: var(--fs-12);
  display: block;
  word-break: keep-all;
  margin-top: 0.5rem;
}
.form-check {
  margin: 0;
  line-height: 40px;
}
.flex-start {
  .form-check {
    margin-right: 1.5rem;
  }
}
.form-label {
  color: var(--bs-dark);
  font-weight: 500;
  &.required {
    &:after {
      content: ' *';
      color: var(--bs-danger);
    }
  }
}
.form-switch {
  padding-left: 2.5rem;
  .form-check-input {
    border: 0;
    width: 2.25rem;
    height: 1.4rem;
    margin-left: -2.25rem;
    background-color: #dde6ff;
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e');
    background-position: left center;
    border-radius: 2.25rem;
    transition: 0.2s ease-in-out;
    &:checked {
      background-color: var(--bs-info);
      border-color: var(--bs-dark);
    }
  }
}
.input-switch {
  &.style-switch-two {
    input {
      &[type='checkbox'] {
        & + label {
          &:after {
            width: 12px;
            height: 12px;
          }
        }
        &:checked {
          & + label {
            background-color: var(--bs-primary);
            &:before {
              background-color: var(--bs-primary);
            }
          }
        }
      }
    }
  }
}
.input-group {
  position: relative;
  &:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
  &:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    border-radius: 0.25rem;
  }
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
      .valid-feedback
    ):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0.5rem;
    border-radius: 0.25rem;
    padding: 0;
    min-width: 120px;
    &.form-control {
      padding: 0.6rem 0.8rem;
    }
  }
  .input-group-text {
    border: 0;
    padding: 0;
    background-color: transparent;
    color: var(--bs-gray);
  }
  .timer {
    font-size: var(--fs-14);
  }
}
.valid-feedback {
  color: var(--bs-success);
}
.invalid-feedback {
  color: var(--bs-danger);
}

// badge
.badge {
  padding: 0.4rem 0.4rem 0.3rem;
  font-size: var(--fs-11);
  font-weight: 500;
  &.md {
    min-width: 60px;
  }
  &.bg-primary {
    background: var(--bs-blue-bg-dark) !important;
    color: var(--bs-primary);
  }
  &.bg-primary-reverse {
    background: var(--bs-primary) !important;
    color: var(--bs-white);
  }
  &.bg-success {
    background: #e5f6ef !important;
    color: var(--bs-success);
  }
  &.bg-danger {
    background: #ffecee !important;
    color: var(--bs-danger);
  }
  &.bg-info {
    background: #326bff !important;
    color: var(--bs-white);
  }
  &.bg-gray {
    background: #959595 !important;
    color: var(--bs-white);
  }
}

// nav
.nav {
  &.type-btn {
    flex-wrap: nowrap;
    .nav-item {
      flex: 1;
      cursor: pointer;
      .nav-link {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--bs-gray);
        font-weight: 500;
        border-radius: 0.25rem;
        font-size: var(--fs-14);
        i {
          color: var(--bs-gray);
          margin-right: 0.5rem;
          position: relative;
        }
        &.active {
          background: var(--bs-primary);
          color: var(--bs-white);
          i {
            color: var(--bs-white);
          }
        }
      }
    }
  }
}

// nav-tab
.nav-tabs {
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  .nav-item {
    cursor: pointer;
    .nav-link {
      border: 0;
      position: relative;
      text-align: center;
    }
  }
  &.type-fill {
    display: inline-flex;
    padding: 0.25rem;
    background: var(--bs-gray-bg);
    border-radius: 8px;
    .nav-item {
      display: inline-flex;
      .nav-link {
        min-width: 140px;
        color: var(--bs-gray);
        padding: 0.35rem 0;
        border-radius: 8px;
        font-size: var(--fs-12);
        font-weight: 700;
        outline: none;
        &.active {
          border: 0;
          color: var(--bs-dark);
          background-color: var(--bs-white);
          box-shadow: 0px 0px 1px rgba(12, 23, 75, 0.1),
            0px 4px 20px rgba(25, 54, 128, 0.1);
        }
      }
    }
  }
  &.type-border {
    border-bottom: 1px solid var(--bs-dark);
    .nav-item {
      flex: 1;
      .nav-link {
        padding: 0.5rem 0;
        font-size: var(--fs-14);
        font-weight: 400;
        color: var(--bs-dark);
        &:after {
          content: '';
          display: none;
          width: 100%;
          height: 2px;
          background: var(--bs-dark);
          position: absolute;
          left: 0;
          bottom: 0;
        }
        &.active {
          font-weight: 700;
          &:after {
            display: block;
          }
        }
      }
    }
  }
}
.tab-content {
  width: 100%;
  & > .tab-pane {
    display: block;
    opacity: 0;
    visibility: hidden;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
    &.active {
      opacity: 1;
      visibility: visible;
      height: inherit;
      overflow: inherit;
      padding: 0.5rem;
      margin: -0.5rem;
    }
  }
}

// card
.card {
  border-color: var(--bs-border);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  .card-header {
    border: 0;
    background: var(--bs-white);
    padding: 1.5rem;
  }
  .card-body {
    padding: 0 1.5rem 1.5rem;
  }
  .card-footer {
    border: 0;
    background: var(--bs-white);
    padding: 0 1.5rem 1.5rem;
    .btn-area {
      margin: 0;
      width: 100%;
      .btn-lg {
        flex-grow: 1;
      }
    }
  }
}

// table
.table {
  table-layout: fixed;
  --bs-table-hover-color: var(--bs-dark);
  --bs-table-hover-bg: rgb(56, 127, 255, 0.1);
  &.table-auto {
    table-layout: auto;
  }
  &.table-hover {
    tbody {
      tr {
        cursor: pointer;
        --bs-table-hover-bg: rgb(56, 127, 255, 0.1);

        &.active {
          --bs-table-bg: rgb(56, 127, 255, 8%);
          outline: 1px solid #bcd4ff; /* 아웃라인 스타일 적용 */
          outline-offset: -1px; /* 테두리와 요소 사이의 간격 조절 */
          border-left: 0; /* 왼쪽 테두리 제거 */
          border-right: 0; /* 오른쪽 테두리 제거 */
        }

        &:hover {
          outline: 1px solid #bcd4ff; /* 아웃라인 스타일 적용 */
          outline-offset: -1px; /* 테두리와 요소 사이의 간격 조절 */
          border-left: 0; /* 왼쪽 테두리 제거 */
          border-right: 0; /* 오른쪽 테두리 제거 */
        }
      }
    }
  }
  &.text-start {
    th,
    td {
      text-align: left;
    }
  }
  th,
  td {
    text-align: center;
    vertical-align: middle;
    color: var(--bs-dark);
  }
  th {
    background: rgba(var(--bs-primary-rgb), 0.1);
    font-size: var(--fs-12);
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    border: 0;
  }
  td {
    padding: 0.75rem 0.5rem;
    font-size: var(--fs-16);
    font-weight: 500;
    .td-ellipsis {
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      overflow: hidden;
      width: inherit;
      width: 100%;
    }
    .btn-area {
      margin: 0;
    }
    &.has-input,
    &.has-thumb {
      padding: 0.25rem 1rem;
    }
  }
  > :not(:first-child) {
    border-top: 0;
  }
  &.sm {
    td {
      padding: 0.5rem;
      font-size: var(--fs-14);
    }
  }
}

.collapse:not(.show) {
  display: inherit;
  visibility: hidden;
  height: 0;
  overflow: hidden;
}

// btn-group
.btn-group {
  &.type-btn {
    flex-wrap: wrap;
    input {
      & + .btn {
        margin: 0 0.5rem 0.5rem 0 !important;
        border: 0;
        background: var(--bs-blue-bg);
        border-radius: 100px !important;
        color: var(--bs-gray);
        font-weight: 500;
        flex: 0 0 auto;
      }

      &:checked {
        & + .btn {
          background: var(--bs-primary);
          color: var(--bs-white);
        }
      }
    }
  }
}
.btn-group-vertical {
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
  input {
    & + .btn {
      margin: 0 !important;
      padding: 1rem 0.75rem;
      justify-content: flex-start;
      background-color: var(--bs-white);
      color: var(--bs-dark);
      font-weight: 500;
      border: 0;
      border-bottom: 1px solid var(--bs-blue-bg-dark) !important;
      border-radius: 0;
      box-shadow: none !important;
      z-index: inherit !important;
    }
    &:checked {
      & + .btn {
        background-color: var(--bs-blue-bg-dark);
        border-color: var(--bs-blue-bg-dark);
        color: var(--bs-dark);
        font-weight: 700;
      }
    }
  }
}

// ratio
.ratio {
  flex-shrink: 0;
  overflow: hidden;
  &.ratio-2x1 {
    --bs-aspect-ratio: 50%;
  }
  &.ratio-2x3 {
    --bs-aspect-ratio: 150%;
  }
  &.ratio-7x2 {
    --bs-aspect-ratio: 28%;
  }
  &.ratio-35x19 {
    --bs-aspect-ratio: 54%;
  }
  &.ratio-70x93 {
    --bs-aspect-ratio: 132%;
  }
  &.ratio-7x10 {
    --bs-aspect-ratio: 142%;
  }
}

// toast
.toast-container {
  padding: 1.5rem;
  .toast {
    .toast-body {
      width: 100%;
      font-size: var(--fs-16);
      text-align: center;
    }
  }
}

// tooltip
.tooltip {
  --bs-tooltip-bg: var(--bs-dark);
  --bs-tooltip-color: var(--bs-white);
  --bs-tooltip-max-width: 300px;
  .tooltip-inner {
    background-color: var(--bs-tooltip-bg);
    color: var(--bs-tooltip-color);
    max-width: var(--bs-tooltip-max-width);
    text-align: left;
  }
}

.markerBox {
  z-index: 200;
  background-color: red;
}

// modal
.upper {
  z-index: 1055;
}

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// HOVER
@media (min-width: 1025px) {
  @media (hover: hover) {
    .btn {
      &:hover {
        color: var(--bs-btn-hover-color);
        background-color: var(--bs-btn-hover-bg);
        border-color: var(--bs-btn-hover-border-color);
      }
    }
    .dropdown {
      .dropdown-menu {
        .dropdown-item {
          &:hover {
            background: var(--bs-gray-100);
          }
        }
      }
    }
  }
}

/* 0 ~ 1340 */
@media screen and (max-width: 1440px) {
  html {
    // font-size: 14px;
  }
}

/* 데이터 피커 disabled 스타일 */
// .react-datepicker__day--disabled {
//   background-color: #d9d2d2;
//   color: #999;
//   cursor: not-allowed;
//   &:hover  {
//     background-color: #d9d2d2;
//     border: 0;
//     color: #999;
//     cursor: not-allowed;
//   }
// }

.dd-file-hover {
  cursor: default; /* default cursor when not hovering */
}

.dd-file-hover:hover {
  cursor: pointer; /* pointer cursor when hovering */
}
.css-dj43q4-MenuList {
  max-height: 200px !important;
}
.pointer {
  cursor: pointer;
}
