// 모바일 헤더
#header {
  height: 80px;
}
.naver-top-btn {
  display: none !important;
}
.br-500 {
  display: block;
}
.br-pc {
  display: none;
}
.br-pc-1024 {
  display: none;
}
// 랜딩
#landing {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .landing-section {
    position: relative;
    background-image: url(../images/img_landing.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: #dba075;
      opacity: 0.75;
      z-index: 1;
    }
    .landing-container {
      position: relative;
      z-index: 2;
      padding: 2rem 1.5rem 8.375rem 1.5rem;
      header {
        .landing-header {
          display: flex;
          justify-content: center;
          .logo-container {
            position: relative;
            width: 164px; // 이미지 크기에 맞춰 조정
            height: 82px; // 이미지 크기에 맞춰 조정
            cursor: pointer;
            .logo {
              position: absolute;
              top: 0;
              left: 0;
              transition: opacity 0.2s ease-in-out;
              width: 100%;
              height: auto;
            }
            .logo-gold {
              opacity: 0;
            }
            &.hovered {
              .logo-gold {
                opacity: 1;
              }
              .logo-white {
                opacity: 0;
              }
            }
          }
        }
      }
      .landing-contact {
        position: relative;
        .landing-text {
          padding-top: 1.5rem;
          margin-bottom: 2rem;
          h2 {
            font-size: 1.625rem;
            font-weight: 400;
            color: #fff;
            line-height: 2.5rem;
            text-align: center;
            margin-bottom: 0;
            span {
              font-weight: 700;
            }
          }
        }
        .contact-list {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 1.5rem;
          .contact-item {
            min-height: 160px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #fff;
            border-radius: 1rem;
            padding: 1.1875rem 1.625rem 1.1875rem 2rem;
            transition: all 0.2s;
            cursor: pointer;
            &:hover {
              background-color: #355955;
              border: 1px solid #355955;
            }
            .contact-title {
              p {
                font-size: 1.375rem;
                font-weight: 500;
                color: #fff;
                line-height: 1.875rem;
              }
              .title {
                font-size: 1.5625rem;
                font-weight: 800;
                line-height: 2.125rem;
              }
              .text {
                font-size: 1.25rem;
                font-weight: 500;
                line-height: 2.125rem;
              }
            }
            .arrow {
              position: relative;
              width: 45px;
              height: 18px;
              display: flex;
              .img,
              .img-hovered {
                position: absolute;
                height: 100%;
                width: 100%;
                img {
                  height: 100%;
                  transition: opacity 0.2s ease-in-out;
                  position: absolute;
                  top: 0;
                  right: 0;
                }
                .normal-img {
                  opacity: 1;
                }
                .hover-img {
                  opacity: 0;
                }
              }
              .img-hovered {
                .normal-img {
                  opacity: 0;
                }
                .hover-img {
                  opacity: 1;
                }
              }
            }
          }
        }
        .contact-options {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          .contact-option {
            display: flex;
            align-items: center;
            border: 1px solid #fff;
            border-radius: 1.5rem;
            padding: 0.5rem 1.25rem;
            transition: all 0.2s;
            cursor: pointer;
            &:hover {
              background-color: #355955;
              border: 1px solid #355955;
            }
            .contact-icon {
              height: 20px;
              display: flex;
              padding-right: 0.625rem;
              img {
                height: 100%;
              }
            }
            .contact-text {
              font-size: var(--fs-20);
              font-weight: 400;
              line-height: 1.875rem;
            }
          }
        }
      }
    }
  }
}
// 메인
#home {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .home-container {
    padding-top: 5rem;
    .main-section {
      .main-container {
        position: relative;
        .main-img {
          position: relative;
          background-color: #000; // 임시 백그라운드
          height: 560px;
          img {
            width: 100%;
            height: 560px;
            object-fit: cover;
          }
        }
        .main-contents {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          padding: 2.5rem 1.5rem 1rem 1.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          z-index: 2;
          .main-top {
            display: flex;
            flex-direction: column;
            .main-box {
              margin-bottom: 1.5rem;
              h2 {
                font-size: 1.625rem;
                font-weight: 800;
                color: #fff;
                line-height: 2.25rem;
                margin-bottom: 0.5rem;
              }
              p {
                font-size: var(--fs-18);
                font-weight: 500;
                color: #fff;
                line-height: 1.75rem;
                margin-bottom: 1.5rem;
              }
            }
            .main-sub-img {
              position: relative;
              display: flex;
              align-items: center;
              img {
                max-width: 312px;
                height: auto;
                width: auto;
              }
            }
          }
          .main-bottom {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .main-btn {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .btn-primary {
                width: 100%;
                max-width: 338px;
                border-radius: 0.25rem;
                font-size: 1.25rem;
                font-weight: 700;
                color: #fff;
                line-height: 2rem;
                margin-bottom: 1rem;
                &:hover {
                  color: #dba075;
                }
              }
              .btn-box {
                max-width: 120px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #aaa;
                border-radius: 1.5rem;
                padding: 0.5rem 1rem;
                cursor: pointer;
                .text {
                  min-width: 55px;
                  font-size: var(--fs-14);
                  font-weight: 500;
                  color: #fff;
                  line-height: 1rem;
                  padding-right: 1.125rem;
                }
                button {
                  height: 12px;
                  color: #fff;
                  padding: 0;
                  img {
                    height: 100%;
                  }
                }
              }
            }
            .banner-gauge {
              width: 100%;
              display: flex;
              gap: 0.375rem;
              padding-top: 1rem;
              span {
                display: inline-block;
                width: 100%; /* 게이지 바 너비 */
                height: 4px; /* 게이지 바 높이 */
                background-color: #aaa; /* 기본 색상 */
                cursor: pointer;
                &.active {
                  background-color: #fff; /* 활성화된 게이지 바 색상 */
                }
              }
            }
          }
        }
      }
    }
    .contents-section1 {
      padding: 5rem 1.5rem 6.625rem 1.5rem;
      .contents-container {
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #252525;
          line-height: 2.5rem;
          text-align: center;
          margin-bottom: 2rem;
        }
        .contents-list {
          display: flex;
          flex-wrap: wrap;
          gap: 1.5rem 1rem;
          .contents-item {
            position: relative;
            width: calc(50% - 0.5rem);
            box-sizing: border-box;
            cursor: pointer;
            .contents-img {
              position: relative;
              height: 148px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 1rem;
              overflow: hidden;
              &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.3);
                transition: background-color 0.3s;
                border-radius: 1rem;
              }
              img {
                width: 100%;
                height: 148px;
                object-fit: cover;
              }
            }
            .contents-text {
              width: 100%;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              display: flex;
              justify-content: center;
              align-items: center;
              .contents-name {
                display: block;
                opacity: 1;
                transition: opacity 0.3s;
                position: absolute;
              }
              .contents-info {
                display: block;
                font-size: 1.5rem;
                line-height: 2.25rem;
                opacity: 0;
                transition: opacity 0.3s;
                position: absolute;
              }
              p {
                font-size: 1.625rem;
                font-weight: 700;
                color: #fff;
                line-height: 1.625rem;
                text-align: center;
              }
            }
            &:hover {
              .contents-img::before {
                background-color: #dba075;
                opacity: 0.8;
              }
            }
            &:hover {
              .contents-name {
                opacity: 0;
              }
              .contents-info {
                opacity: 1;
              }
            }
          }
        }
      }
    }
    .contents-section2 {
      .contents-container {
        background-image: url('../images/img_home5.jpg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 500px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.6);
          z-index: 1;
        }
        .contents-box {
          padding: 0 1.5rem;
          z-index: 2;
          h2 {
            font-size: 1.625rem;
            font-weight: 700;
            color: #fff;
            line-height: 1.875rem;
            text-align: center;
            margin: 0;
          }
          .title {
            padding-top: 0.5rem;
            letter-spacing: 3.12px;
          }
          hr {
            color: #d79262;
            opacity: 1;
            margin: 2rem 0;
          }
          p {
            font-size: var(--fs-18);
            font-weight: 500;
            color: #fff;
            line-height: 1.625rem;
            text-align: center;
            margin-bottom: 3.5rem;
          }
          .btn-introduction {
            width: 100%;
            max-width: 338px;
            font-size: var(--fs-14);
            font-weight: 700;
            line-height: 1.5rem;
            margin: 0 auto;
            padding: 0.5rem 1.5rem;
            border-radius: 0.25rem;
          }
        }
      }
    }
    .contents-section3 {
      padding: 5rem 1.5rem;
      .contents-container {
        .title {
          h2 {
            font-size: 1.625rem;
            font-weight: 800;
            color: #252525;
            line-height: 2.5rem;
            margin-bottom: 1.5rem;
          }
        }
        .contents-box {
          .contents-list {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin-bottom: 1.875rem;
            .contents-item {
              width: 80%;
              .text-list {
                list-style: inside;
                font-size: var(--fs-16);
                font-weight: 500;
                color: #252525;
                line-height: 1.5rem;
              }
              p {
                font-size: var(--fs-16);
                font-weight: 500;
                color: #252525;
                line-height: 2rem;
              }
              .text {
                font-weight: 700;
                color: #d79262;
              }
            }
            .responsive-span {
              display: none;
            }
          }
          .contents-info {
            .info {
              display: flex;
              gap: 0 0.25rem;
              p {
                font-size: var(--fs-14);
                font-weight: 500;
                color: #d1844d;
                line-height: 1.5rem;
              }
            }
          }
        }
      }
    }
    .contents-section4 {
      margin-bottom: 11.0625rem;
      .contents-container {
        .title {
          margin-bottom: 1.5rem;
          padding: 0 1.5rem;
          h2 {
            font-size: 1.625rem;
            font-weight: 800;
            color: #252525;
            line-height: 2.5rem;
          }
        }
        .contents-box {
          .map {
            height: 240px;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
          }
          .contents-list {
            .contents-item {
              padding: 1.25rem 1.5rem 0 1.5rem;
              p {
                font-size: var(--fs-16);
                font-weight: 500;
                color: #252525;
                line-height: 2rem;
              }
              .text {
                font-weight: 700;
                color: #d79262;
              }
            }
          }
        }
      }
    }
  }
  .top-btn {
    z-index: 8;
  }
}
// About
// 한의원 소개
#introduction {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 224px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 224px;
        object-fit: cover;
      }
    }
  }
  .contents-box {
    .contents-section {
      padding-top: 2.5rem;
      margin-bottom: 4rem;
      .contents-img {
        position: relative;
        height: 176px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 176px;
          object-fit: cover;
        }
      }
      &.top-section {
        padding: 2.5rem 1.5rem 0 1.5rem;
        margin-bottom: 3.75rem;
      }
      .contents-container {
        padding: 4rem 1.5rem 0 1.5rem;
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #d79262;
          line-height: 2.25rem;
          margin-bottom: 1.5rem;
        }
        .contents-text {
          &.text-box {
            display: flex;
            flex-direction: column;
            gap: 2rem;
          }
          p {
            font-size: 1rem;
            font-weight: 500;
            color: #252525;
            line-height: 2rem;
            word-break: keep-all;
            white-space: normal;
            span {
              font-weight: 700;
            }
          }
          .text {
            margin-bottom: 1.5rem;
          }
          .text1 {
            margin-bottom: 1.1875rem;
          }
          .text2 {
            margin-bottom: 2rem;
          }
        }
      }
      &.reservation-section {
        margin-bottom: 12.5rem;
        .contents-container {
          padding-top: 4rem;
          h2 {
            text-align: center;
            margin-bottom: 1.5rem;
          }
          .contents-text {
            text-align: center;
            margin-bottom: 1.5rem;
          }
          .btn-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1.5rem 0;
            .btn-outline {
              max-width: 384px;
              width: 100%;
              .btn-outline-primary {
                width: 100%;
                padding: 1rem 1.5rem;
                border-radius: 0.25rem;
                font-size: var(--fs-16);
                font-weight: 700;
                line-height: 1.5rem;
              }
            }
            .title-btn {
              max-width: 384px;
              width: 100%;
              .btn {
                width: 100%;
                font-size: var(--fs-20);
                font-weight: 700;
                line-height: 2rem;
                padding: 1rem 1.5rem;
                border-radius: 50px;
              }
            }
          }
        }
      }
      &.info-section {
        padding: 6.25rem 1.5rem 0 1.5rem;
        margin-bottom: 10rem;
        .contents-container {
          margin-bottom: 3.125rem;
        }
        .contents-list {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 3.25rem;
          .contents-item {
            .contents-img-box {
              width: 260px;
              height: 260px;
              display: flex;
            }
            p {
              font-size: var(--fs-18);
              font-weight: 700;
              color: #dba075;
              line-height: 2rem;
              text-align: center;
            }
            .info-title {
              padding-top: 1.25rem;
            }
            .info-sub-title {
              font-size: 1.375rem;
              margin-bottom: 0.5rem;
            }
            .info-text {
              font-weight: 500;
              color: #252525;
            }
          }
        }
      }
    }
  }
}
// 의료진 소개
#doctors {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
      .main-text-box {
        width: 100%;
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1.5rem;
        .main-text {
          width: 50%;
          font-size: var(--fs-20);
          font-weight: 700;
          color: #fff;
          line-height: 2rem;
          font-family: 'Noto Serif KR', serif;
        }
        .main-pd-right {
          text-align: right;
          padding-right: 25%;
        }
        .main-pd-left {
          padding-left: 29%;
        }
      }
    }
    .pc-main-img {
      display: none;
    }
  }
  .contents-box {
    padding: 0 1.5rem;
    .contents-section {
      // padding-top: 2.5rem;
      .contents-container {
        padding-top: 1.5rem;
        margin-bottom: 5.9375rem;
        &.contents-dt-container {
          margin-bottom: 5.5rem;
        }
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #d79262;
          line-height: 2.25rem;
          margin-bottom: 1.5rem;
        }
        .contents-img {
          position: relative;
          height: 244px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.5rem;
          overflow: hidden;
          img {
            width: 100%;
            height: 244px;
            object-fit: cover;
          }
        }
        .contents-list {
          margin-bottom: 8rem;
          &.contents-info {
            margin-bottom: 2rem;
          }
          .contents-main-title {
            font-size: 1.875rem;
            font-weight: 700;
            color: #d79262;
            line-height: normal;
            text-align: center;
            font-family: 'Noto Serif KR', serif;
            padding-top: 2rem;
            margin-bottom: 2.5rem;
          }
          h4 {
            font-size: 1.625rem;
            font-weight: 700;
            color: #252525;
            line-height: 2rem;
            margin-bottom: 1rem;
            span {
              font-size: 1.25rem;
            }
            &.name {
              padding-top: 2.5rem;
            }
          }
          .contents-title {
            margin-bottom: 2rem;
          }
          .contents-text {
            display: none;
            &.m-contents-text {
              display: block;
              margin-bottom: 2.5rem;
            }
          }
          .contents-item {
            padding-top: 1.5rem;
            p {
              font-size: var(--fs-16);
              font-weight: 500;
              color: #252525;
              line-height: 2rem;
            }
            .title {
              font-weight: 700;
              color: #d79262;
            }
          }
          .m-contents-detail {
            // padding-top: 2rem;
          }
        }
        .main-contents-list {
          display: none;
        }
        .text {
          font-size: var(--fs-16);
          font-weight: 600;
          color: #d1844d;
          line-height: 2rem;
          margin-bottom: 1.5rem;
          font-family: 'Noto Serif KR', serif;
          span {
            font-weight: 900;
            font-family: 'Noto Serif KR', serif;
          }
        }
        .contents-list-box {
          display: flex;
          flex-direction: column;
          gap: 6rem;
          .contents-item-img {
            width: 100%;
            height: 244px;
            border-radius: 0.5rem;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .contents-dt-list {
            padding-top: 2rem;
            .name {
              min-width: 137px;
              padding-top: 0;
              margin-bottom: 1rem;
            }
            .contents-group {
              display: flex;
              flex-direction: column;
              row-gap: 1.5rem;
            }
          }
        }
      }
    }
  }
  .special-section {
    .contents-container {
      &.pc-container {
        display: none;
      }
      &.m-container {
        display: flex;
        position: relative;
        margin-bottom: 4.5rem;
        .contents-text-group {
          position: absolute;
          padding: 3rem 1.5rem 0 1.5rem;
          .contents-title-box {
            display: flex;
            align-items: flex-end;
            column-gap: 0.25rem;
            p {
              font-size: var(--fs-20);
              font-weight: 600;
              color: #fff;
              line-height: normal;
            }
            h2 {
              font-size: 1.625rem;
              font-weight: 700;
              color: #fff;
              line-height: normal;
              margin-bottom: 0;
            }
          }
          .contents-text-box {
            display: flex;
            flex-direction: column;
            row-gap: 1.5rem;
            padding-top: 1rem;
            .contents-text-detail {
              font-weight: 700;
              line-height: 2rem;
            }
            p {
              font-size: var(--fs-16);
              font-weight: 500;
              color: #fff;
              line-height: 2rem;
            }
          }
        }
      }
    }
    .contents-m-text-group {
      padding: 0 1.5rem;
      margin-bottom: 8.5rem;
      .contents-title-box {
        font-size: 1.625rem;
        font-weight: 700;
        color: #252525;
        line-height: 2rem;
        margin-bottom: 1rem;
        padding-top: 3rem;
        span {
          font-size: 1.25rem;
        }
      }
      .contents-text-list {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
      }
      .contents-text-box {
        p {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #252525;
          line-height: 2rem;
        }
        .contents-text-detail {
          font-weight: 700;
          color: #d79262;
        }
      }
    }
  }
}
// Hours 지점 안내 페이지와 통합 예정
// #hours {
//     .main-section {
//         .main-img {

//             background-repeat: no-repeat;
//             background-position: center;
//             background-size: cover;
//             height: 176px;
//         }
//     }
//     .contents-section {
//         padding-top: 2.5rem;
//         margin-bottom: 5.5rem;
//         .hours-container {
//             padding: 0 1.5rem;
//             h2 {
//                 font-size: 1.625rem;
//                 font-weight: 800;
//                 color: #D79262;
//                 line-height: 2.5rem;
//                 margin-bottom: 1.5rem;
//             }
//             .hours-item {
//                 margin-bottom: 1rem;
//                 p {
//                     font-size: var(--fs-16);
//                     font-weight: 500;
//                     color: #252525;
//                     line-height: 2rem;
//                 }
//                 .title {
//                     font-weight: 700;
//                     color: #D79262;
//                 }
//             }
//             .hours-info {
//                 padding-top: 0.5rem;
//                 li {
//                     list-style: inside;
//                     font-size: var(--fs-14);
//                     font-weight: 500;
//                     color: #252525;
//                     line-height: 1.5rem;
//                 }
//             }
//             .btn-box {
//                 display: flex;
//                 justify-content: center;
//                 padding-top: 3rem;
//             }
//         }
//     }
// }
// 지점 안내
#directions {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 244px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 244px;
        object-fit: cover;
      }
    }
  }
  .contents-section {
    padding-top: 2.25rem;
    flex-grow: 1;
    .contents-container {
      .contents-box {
        padding: 0 1.5rem;
      }
      .contents-list {
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #252525;
          line-height: 2.5rem;
          margin-bottom: 1rem;
        }
        .directions-item {
          p {
            font-size: var(--fs-16);
            font-weight: 500;
            color: #252525;
            line-height: 1.5rem;
          }
          .title {
            font-weight: 700;
            color: #d79262;
          }
          &.detail {
            padding-top: 1.25rem;
          }
          .text-list {
            list-style: inside;
            font-size: var(--fs-16);
            font-weight: 500;
            color: #252525;
            line-height: 1.5rem;
          }
        }
        .directions-detail {
          padding-top: 1.5rem;
          margin-bottom: 5rem;
          .directions-item {
            display: flex;
            gap: 0.5rem;
          }
        }
        &.contents-2 {
          margin-bottom: 2.875rem;
          .directions-item {
            margin-bottom: 1.5rem;
            .directions-info {
              li {
                list-style: inside;
                font-size: var(--fs-16);
                font-weight: 500;
                color: #252525;
                line-height: 2rem;
                span {
                  font-weight: 700;
                }
                &.last-style {
                  list-style: none;
                }
              }
            }
            p {
              span {
                font-weight: 700;
              }
            }
          }
        }
        &.contents-3 {
          margin-top: 2.5rem;
          margin-bottom: 5rem;
          .contents-container {
            padding: 0 1.5rem;
            .directions-box {
              .directions-item {
                display: flex;
                margin-bottom: 1.75rem;
                .m-title {
                  min-width: 115px;
                }
              }
            }
            .directions-info {
              padding-top: 1.5rem;
              li {
                list-style: inside;
                font-size: var(--fs-14);
                font-weight: 500;
                color: #252525;
                line-height: 1.5rem;
              }
            }
          }
        }
      }
      .map {
        height: 240px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }
      .btn-box {
        max-width: 338px;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding-top: 3.4375rem;
        margin-bottom: 13.0625rem;
        button {
          width: 100%;
          font-size: var(--fs-20);
          font-weight: 700;
          line-height: 2rem;
          padding: 1rem 1.5rem;
          border-radius: 50px;
        }
      }
    }
  }
}
// 키성장
#growth {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
      }
      .main-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // width: 800px;
        h2 {
          font-size: 1.625rem;
          font-weight: 700;
          color: #fff;
          line-height: 1.875rem;
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .text {
          min-width: 240px;
          font-size: 1.375rem;
          font-weight: 400;
          color: #fff;
          line-height: 1.375rem;
          text-align: center;
        }
      }
    }
  }
  .contents-section1 {
    padding-top: 2.5rem;
    .contents-container {
      background-image: none;
      .contents-text {
        padding: 0 1.5rem;
        margin-bottom: 2.375rem;
        &.contents-iframe {
          position: relative;
          width: 100%;
          overflow: hidden;
          padding-top: 56.25%;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          }
        }
        h2 {
          font-size: 1.875rem;
          font-weight: 800;
          color: #dba075;
          line-height: 2.625rem;
          margin-bottom: 1.5rem;
        }
        p {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #252525;
          line-height: 2rem;
          margin-bottom: 1rem;
          span {
            font-weight: 700;
            color: #d79262;
          }
        }
      }
      .contents-img {
        padding: 0 1.5rem;
        position: relative;
        height: 196px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 196px;
          object-fit: cover;
        }
      }
    }
  }
  .contents-section2 {
    margin-bottom: 5rem;
    .contents-container {
      .contents-text {
        padding: 2.375rem 1.5rem 2.125rem 1.5rem;
        p {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #252525;
          line-height: 2rem;
        }
      }
      .contents-img {
        padding: 0 1.5rem;
        position: relative;
        height: 480px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 480px;
          object-fit: cover;
          object-position: top;
        }
      }
    }
  }
  .contents-section3 {
    padding: 0 1.5rem;
    .contents-container {
      margin-bottom: 5rem;
      .contents-text {
        h2 {
          font-size: 1.875rem;
          font-weight: 800;
          color: #d79262;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
        }
        p {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #252525;
          line-height: 2rem;
        }
      }
    }
  }
  .contents-section4 {
    .contents-container {
      position: relative;
      background-image: url('../images/img_growth4.jpg');
      background-repeat: no-repeat;
      background-position: 25%;
      background-size: cover;
      min-height: 728px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .contents-text {
        position: relative;
        padding: 0 1.5rem;
        h2 {
          font-size: 1.875rem;
          font-weight: 800;
          color: #dba075;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
          span {
            color: #fff;
          }
        }
        .text {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #fff;
          line-height: 2rem;
          margin-bottom: 1.5rem;
          span {
            font-weight: 700;
            color: #d79262;
          }
        }
        .contents-text-box {
          padding: 1.5rem 1rem;
          background: rgba(0, 0, 0, 0.5);
          border-radius: 0.5rem;
          width: 100%;
          max-width: 560px;
          p {
            font-size: var(--fs-12);
            font-weight: 500;
            color: #db9e73;
            line-height: 1.25rem;
          }
          .sub-text {
            padding-left: 0.5rem;
          }
        }
      }
    }
  }
  .contents-section5 {
    padding: 5rem 1.5rem;
    .contents-container {
      .contents-text {
        margin-bottom: 2.5rem;
        .contents-img {
          width: 54px;
          display: flex;
          margin-bottom: 1rem;
        }
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #d79262;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
          span {
            color: #a25c2a;
          }
        }
        p {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #7d4821;
          line-height: 2rem;
        }
      }
      .contents-box {
        padding: 1.6875rem 2.5rem 1.5rem 2.5rem;
        background-color: #fbf4ef;
        border-radius: 1rem;
        .title {
          font-size: var(--fs-20);
          font-weight: 700;
          color: #252525;
          line-height: 1.875rem;
          margin-bottom: 1.5rem;
        }
        ul {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .contents-item {
            display: flex;
            align-items: center;
            .contents-icon {
              min-width: 64px;
              width: 64px;
              height: 64px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            p {
              padding-left: 0.75rem;
              font-size: var(--fs-16);
              font-weight: 700;
              color: #a65f2b;
              line-height: 1.5rem;
            }
          }
        }
      }
    }
  }
  .contents-section6 {
    .contents-container {
      position: relative;
      background-image: url('../images/img_growth5.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      min-height: 614px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .contents-box {
        position: relative;
        z-index: 2;
        padding: 0 2.25rem;
        h2 {
          font-size: var(--fs-24);
          font-weight: 700;
          color: #fff;
          line-height: 2.25rem;
          text-align: center;
          margin-bottom: 2.5rem;
          span {
            color: #db9e73;
          }
        }
        .btn {
          max-width: 312px;
          width: 100%;
          font-size: var(--fs-20);
          line-height: 2rem;
          padding: 1rem 0;
          border-radius: 50px;
          margin: 0 auto;
        }
      }
    }
  }
  .contents-section7 {
    padding: 5rem 1.5rem;
    .contents-container {
      h2 {
        font-size: 1.625rem;
        font-weight: 800;
        color: #d79262;
        line-height: 2.5rem;
        margin-bottom: 1.5rem;
      }
      ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        .contents-item {
          background-color: #f8f8f8;
          border-radius: 0.5rem;
          padding: 2rem 1.5rem;
          .number {
            font-size: var(--fs-14);
            font-weight: 800;
            color: #db9e73;
            line-height: 0.875rem;
            margin-bottom: 0.5rem;
          }
          .title {
            font-style: var(--fs-18);
            font-weight: 700;
            color: #252525;
            line-height: 1.5rem;
            margin-bottom: 0.5rem;
          }
          p {
            font-size: var(--fs-16);
            font-weight: 500;
            color: #737373;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
  .contents-section8 {
    .contents-container {
      .contents-box {
        background-color: #fbf4ef;
        padding: 5rem 1.5rem;
        .contents-icon {
          min-width: 44px;
          width: 44px;
          margin-bottom: 0.4375rem;
        }
        h2 {
          font-size: var(--fs-24);
          font-weight: 800;
          color: #d79262;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
        }
        .btn-outline {
          max-width: 360px;
          width: 100%;
          font-size: var(--fs-18);
          font-weight: 700;
          line-height: 1.5rem;
          border-radius: 0.25rem;
          padding: 1.25rem 0;
          border: 2px solid #d79262;
        }
      }
    }
  }
  .contents-section9 {
    padding-top: 5rem;
    margin-bottom: 7.5rem;
    .contents-container {
      background-color: #f8f8f8;
      padding: 5rem 1.5rem;
      .contents-img {
        margin-bottom: 1.5rem;
      }
      h2 {
        font-size: var(--fs-24);
        font-weight: 800;
        color: #d79262;
        line-height: 2.25rem;
        margin-bottom: 1.5rem;
        span {
          color: #a25c2a;
        }
      }
      .btn {
        max-width: 440px;
        width: 100%;
        font-size: var(--fs-20);
        line-height: 2rem;
        padding: 1rem 0;
        border-radius: 50px;
      }
    }
  }
}
// 전립선
#prostate {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
        object-position: 70% center;
      }
      .main-text {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h2 {
          font-size: 1.625rem;
          font-weight: 700;
          color: #fff;
          line-height: 1.875rem;
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .text {
          font-size: 1.375rem;
          font-weight: 400;
          color: #fff;
          line-height: 1.375rem;
          text-align: center;
        }
      }
    }
  }
  .contents-section1 {
    padding-top: 9rem;
    &.contents-section-top {
      padding-top: 4.1875rem;
      padding-bottom: 0;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      .contents-group {
        display: flex;
        justify-content: space-between;
        .contents-text-box {
          width: 100%;
        }
        .pc-contents-img {
          display: none;
        }
        .m-contents-img {
          display: block;
        }
      }
      .contents-text-top {
        margin-bottom: 2rem;
        .sub-title {
          margin-bottom: 1.6875rem;
          h2 {
            font-size: 1.875rem;
            font-weight: 800;
            color: #dba075;
            line-height: 2.625rem;
            margin-bottom: 0;
          }
        }
        .sub-text {
          .text {
            font-size: var(--fs-16);
            font-weight: 500;
            color: #252525;
            line-height: 2rem;
            margin: 0;
          }
        }
      }
      .contents-text-bottom {
        padding-top: 1.6875rem;
        .sub-title {
          margin-bottom: 2rem;
          h2 {
            font-size: 1.5rem;
            font-weight: 700;
            color: #dba075;
            line-height: 2rem;
            margin-bottom: 0.5rem;
          }
          p {
            font-size: var(--fs-18);
            font-weight: 500;
            color: #252525;
            line-height: 1.75rem;
            margin: 0;
          }
        }
        .sub-text {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          .sub-title-text {
            h2 {
              font-size: 1.25rem;
              font-weight: 700;
              color: #dba075;
              line-height: 2rem;
              margin-bottom: 0;
            }
            .text {
              font-size: var(--fs-18);
              font-weight: 500;
              color: #252525;
              line-height: 1.75rem;
              margin: 0;
            }
          }
        }
      }
    }
    .contents-container {
      .contents-text {
        padding: 0 1.5rem;
        margin-bottom: 5rem;
        h2 {
          font-size: 1.875rem;
          font-weight: 800;
          color: #dba075;
          line-height: 2.625rem;
          margin-bottom: 1.5rem;
        }
        p {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #252525;
          line-height: 2rem;
          span {
            font-weight: 700;
            color: #d79262;
          }
        }
        .text {
          margin-bottom: 1rem;
        }
        .link-text {
          font-size: var(--fs-14);
          color: #d79262;
          line-height: 1.25rem;
          .icon {
            display: inline-block;
            width: 20px;
            margin-right: 0.25rem;
          }
        }
      }
      .contents-item {
        padding: 0 1.5rem;
        margin-bottom: 5rem;
        .contents-img {
          padding-top: 1.75rem;
          margin-bottom: 2.5rem;
          display: flex;
          justify-content: center;
        }
        .contents-pc-img {
          display: none;
        }
        p {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #252525;
          line-height: 2rem;
          span {
            font-weight: 700;
            color: #d79262;
          }
        }
      }
    }
  }
  .contents-section2 {
    .contents-container {
      position: relative;
      background-image: url('../images/img_prostate3.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      min-height: 728px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .contents-text {
        position: relative;
        padding: 0 1.5rem;
        h2 {
          font-size: 1.875rem;
          font-weight: 800;
          color: #fff;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
          span {
            color: #db9e73;
          }
        }
        .text-box {
          .text {
            font-size: var(--fs-16);
            font-weight: 500;
            color: #fff;
            line-height: 2rem;
            margin-bottom: 1.75rem;
            span {
              font-weight: 700;
              color: #d79262;
            }
          }
        }
      }
    }
  }
  .contents-section3 {
    padding: 5rem 1.5rem;
    .contents-container {
      .contents-box {
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #d79262;
          line-height: 2.5rem;
          margin-bottom: 2.5rem;
        }
        .contents-list {
          display: flex;
          flex-direction: column;
          gap: 2.5rem;
          .contents-item {
            .contents-img {
              margin-bottom: 1rem;
              border-radius: 0.5rem;
              overflow: hidden;
              position: relative;
              height: 180px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 100%;
                height: 180px;
                object-fit: cover;
              }
            }
            .title {
              font-size: var(--fs-18);
              font-weight: 700;
              color: #252525;
              line-height: 1.5rem;
              margin-bottom: 0.5rem;
              span {
                font-weight: 700;
                color: #d79262;
                padding-right: 0.5rem;
              }
            }
            .info {
              font-size: var(--fs-16);
              font-weight: 500;
              color: #737373;
              line-height: 1.5rem;
              padding-left: 1rem;
            }
          }
        }
        .text {
          font-size: 1rem;
          font-weight: 500;
          color: #d79262;
          line-height: 2rem;
          padding-top: 2.125rem;
        }
      }
    }
  }
  .contents-section4 {
    background-color: #f8f8f8;
    .contents-container {
      padding: 5rem 1.5rem;
      h2 {
        font-size: 1.375rem;
        font-weight: 800;
        color: #252525;
        line-height: 2.5rem;
        text-align: left;
        margin-bottom: 2.5rem;
      }
      .contents-list {
        .contents-item {
          padding-top: 2rem;
          .title {
            font-size: var(--fs-18);
            font-weight: 700;
            color: #d1844d;
            line-height: 1.5rem;
            margin-bottom: 0.5rem;
          }
          .contents-box {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            background-color: #fff;
            border-radius: 0.5rem;
            padding: 2rem 1.5rem;
            .contents-info {
              display: flex;
              .sub-title {
                font-size: var(--fs-18);
                font-weight: 700;
                color: #252525;
                line-height: 1.5rem;
                margin-bottom: 0.5rem;
              }
              .sub-info {
                font-size: var(--fs-16);
                font-weight: 500;
                color: #737373;
                line-height: 1.5rem;
              }
              &::before {
                content: '•';
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 8px;
                color: #000;
                font-size: 2.5rem;
              }
            }
          }
        }
      }
      .text {
        font-size: var(--fs-14);
        font-weight: 500;
        color: #d1844d;
        line-height: 1.25rem;
        padding-top: 2.5rem;
      }
    }
  }
  .contents-section5 {
    .contents-container {
      .contents-box {
        background-color: #fbf4ef;
        padding: 5rem 1.5rem;
        .contents-icon {
          min-width: 44px;
          width: 44px;
          margin-bottom: 0.4375rem;
        }
        h2 {
          font-size: var(--fs-24);
          font-weight: 800;
          color: #d79262;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
        }
        .btn-outline {
          max-width: 360px;
          width: 100%;
          font-size: var(--fs-18);
          font-weight: 700;
          line-height: 1.5rem;
          border-radius: 0.25rem;
          padding: 1.25rem 0;
          border: 2px solid #d79262;
        }
      }
    }
  }
  .contents-section6 {
    padding-top: 5rem;
    margin-bottom: 7.5rem;
    .contents-container {
      background-color: #f8f8f8;
      padding: 5rem 1.5rem;
      .contents-img {
        margin-bottom: 1.5rem;
      }
      h2 {
        font-size: var(--fs-24);
        font-weight: 800;
        color: #d79262;
        line-height: 2.25rem;
        margin-bottom: 1.5rem;
        span {
          color: #a25c2a;
        }
      }
      .btn {
        max-width: 440px;
        width: 100%;
        font-size: var(--fs-20);
        line-height: 2rem;
        padding: 1rem 0;
        border-radius: 50px;
      }
    }
  }
  .call-btn {
    width: 143px;
    height: 143px;
    right: 25px;
    img {
      width: 27px;
      height: 27px;
    }
    p {
      font-size: var(--fs-18);
      line-height: 1.375rem;
    }
  }
}
// 집중력
#Focus {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
      }
      .main-text {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h2 {
          font-size: 1.625rem;
          font-weight: 700;
          color: #fff;
          line-height: 1.875rem;
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .text {
          font-size: 1.375rem;
          font-weight: 400;
          color: #fff;
          line-height: 1.375rem;
          text-align: center;
        }
      }
    }
    .main-contents-text {
      position: relative;
      z-index: 2;
      padding: 2.5rem 1.5rem 5rem 1.5rem;
      h2 {
        font-size: 1.625rem;
        font-weight: 800;
        color: #d79262;
        line-height: 2.5rem;
        margin-bottom: 1.5rem;
      }
      p {
        font-size: var(--fs-16);
        font-weight: 500;
        color: #252525;
        line-height: 2rem;
        margin-bottom: 1.5rem;
        span {
          font-weight: 700;
          color: #d1844d;
        }
      }
    }
  }
  .contents-section1 {
    margin-bottom: 5rem;
    .contents-container {
      padding: 0 1.5rem;
      .contents-img {
        position: relative;
        height: 176px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 176px;
          object-fit: cover;
        }
      }
      .contents-text {
        position: relative;
        z-index: 2;
        padding-top: 2.5rem;
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #d79262;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
        }
        p {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #252525;
          line-height: 2rem;
          margin-bottom: 1rem;
        }
        .text-box {
          margin-bottom: 2rem;
          .index-text {
            display: flex;
            flex-direction: column;
            padding-top: 1.5rem;
            li {
              font-size: var(--fs-16);
              font-weight: 700;
              color: #d79262;
              line-height: 2rem;
            }
          }
        }
      }
    }
  }
  .contents-section2 {
    margin-bottom: 5rem;
    .contents-container {
      padding: 0 1.5rem;
      .contents-img {
        position: relative;
        height: 176px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
        overflow: hidden;
        img {
          width: 100%;
          height: 176px;
          object-fit: cover;
        }
      }
      .contents-text {
        position: relative;
        padding-top: 4.5rem;
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #d79262;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
        }
        p {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #252525;
          line-height: 2rem;
          margin-bottom: 1rem;
        }
        .text-box {
          margin-bottom: 2rem;
          .sub-text {
            display: flex;
            flex-direction: column;
            li {
              font-size: var(--fs-16);
              font-weight: 700;
              color: #d79262;
              line-height: 2rem;
            }
          }
        }
      }
    }
  }
  .contents-section3 {
    margin-bottom: 5rem;
    .contents-container {
      padding: 0 1.5rem;
      .contents-box {
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #d79262;
          line-height: 2.5rem;
          text-align: center;
          margin-bottom: 1.5rem;
        }
        .contents-list {
          display: flex;
          flex-direction: column;
          gap: 3rem;
          .contents-item {
            .contents-img {
              position: relative;
              height: 176px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0.5rem;
              overflow: hidden;
              margin-bottom: 1rem;
              img {
                width: 100%;
                height: 176px;
                object-fit: cover;
              }
            }
            .title {
              font-size: var(--fs-18);
              font-weight: 700;
              color: #252525;
              line-height: 1.5rem;
              margin-bottom: 0.5rem;
              span {
                font-weight: 700;
                color: #d79262;
                padding-right: 0.5rem;
              }
            }
            .info {
              font-size: var(--fs-16);
              font-weight: 500;
              color: #737373;
              line-height: 1.5rem;
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }
  .contents-section4 {
    padding: 5rem 0;
    margin-bottom: 5rem;
    background-color: #f8f8f8;
    .contents-container {
      padding: 0 1.5rem;
      .contents-text {
        margin-bottom: 2.625rem;
        .contents-img {
          width: 54px;
          margin-bottom: 1rem;
          display: flex;
        }
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #d79262;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
          span {
            color: #a25c2a;
          }
        }
        p {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #7d4821;
          line-height: 2rem;
        }
      }
      .contents-box {
        h2 {
          font-size: var(--fs-20);
          font-weight: 700;
          color: #252525;
          line-height: 1.875rem;
          margin-bottom: 1.5rem;
        }
        .contents-list {
          display: flex;
          flex-direction: column;
          gap: 2.5rem;
          .contents-item {
            .contents-img {
              position: relative;
              height: 180px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0.5rem;
              overflow: hidden;
              margin-bottom: 0.75rem;
              img {
                width: 100%;
                height: 180px;
                object-fit: cover;
              }
              .gmp-img {
                width: 50px;
                height: 41px;
                position: absolute;
                right: 24px;
                bottom: 16px;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .title {
              font-size: var(--fs-18);
              font-weight: 700;
              color: #252525;
              line-height: 1.5rem;
              margin-bottom: 0.5rem;
              span {
                font-weight: 700;
                color: #d79262;
                padding-right: 0.5rem;
              }
            }
            .info {
              font-size: var(--fs-16);
              font-weight: 500;
              color: #737373;
              line-height: 1.5rem;
              padding-left: 1rem;
            }
          }
        }
      }
    }
  }
  .contents-section5 {
    .contents-container {
      .contents-box {
        background-color: #fbf4ef;
        padding: 5rem 1.5rem;
        .contents-icon {
          min-width: 44px;
          width: 44px;
          margin-bottom: 0.4375rem;
        }
        h2 {
          font-size: var(--fs-24);
          font-weight: 800;
          color: #d79262;
          line-height: 2.5rem;
          margin-bottom: 1.5rem;
        }
        .btn-outline {
          max-width: 360px;
          width: 100%;
          font-size: var(--fs-18);
          font-weight: 700;
          line-height: 1.5rem;
          border-radius: 0.25rem;
          padding: 1.25rem 0;
          border: 2px solid #d79262;
        }
      }
    }
  }
  .contents-section6 {
    padding-top: 5rem;
    margin-bottom: 7.5rem;
    .contents-container {
      background-color: #f8f8f8;
      padding: 5rem 1.5rem;
      .contents-img {
        margin-bottom: 1.5rem;
      }
      h2 {
        font-size: var(--fs-24);
        font-weight: 800;
        color: #d79262;
        line-height: 2.25rem;
        margin-bottom: 1.5rem;
        span {
          color: #a25c2a;
        }
      }
      .btn {
        max-width: 440px;
        width: 100%;
        font-size: var(--fs-20);
        line-height: 2rem;
        padding: 1rem 0;
        border-radius: 50px;
      }
    }
  }
}
// 다이어트
#diet {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
      }
      .main-text {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h2 {
          font-size: 1.625rem;
          font-weight: 700;
          color: #fff;
          line-height: 1.875rem;
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .text {
          font-size: 1.375rem;
          font-weight: 400;
          color: #fff;
          line-height: 1.375rem;
          text-align: center;
        }
      }
    }
    .main-contents-text {
      position: relative;
      z-index: 2;
      padding: 3rem 2.5625rem;
      display: flex;
      justify-content: center;
      align-items: center;
      h2 {
        font-size: 3rem;
        font-weight: 800;
        color: #121212;
        line-height: 4rem;
        margin-bottom: 0;
      }
      .main-contents-special-text {
        color: #b63fff;
      }
    }
  }
  .contents-section1 {
    background-color: #121212;
    margin-bottom: 5rem;
    .contents-container {
      padding: 5rem 2.3125rem;
      .contents-box1 {
        margin-bottom: 4rem;
        h2 {
          font-size: 3rem;
          font-weight: 800;
          color: #b63fff;
          line-height: 4rem;
          text-align: center;
          margin-bottom: 1.125rem;
        }
        p {
          font-size: 2.5rem;
          font-weight: 800;
          color: #fff;
          line-height: 4rem;
          text-align: center;
        }
      }
      .contents-box2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4rem;
        .info-box {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          .info-title {
            display: inline-block;
            font-size: var(--fs-20);
            font-weight: 800;
            color: #000;
            line-height: 1.5rem;
            padding: 0.5rem 1rem;
            background-color: #db9e73;
            border-radius: 4rem;
            box-shadow: 0px -3px 2px 0px rgba(0, 0, 0, 0.15) inset,
              3px 2px 2px 0px rgba(255, 255, 255, 0.2) inset;
          }
          h2 {
            font-size: 3.5rem;
            font-weight: 800;
            color: #bb4dff;
            line-height: 3.5rem;
            text-align: center;
            margin-bottom: 0;
            span {
              font-size: 2rem;
            }
          }
          p {
            font-size: var(--fs-14);
            font-weight: 600;
            color: #8c8c8c;
            line-height: 1.375rem;
            text-align: center;
          }
        }
      }
    }
  }
  .contents-section2 {
    margin-bottom: 5rem;
    .contents-container {
      padding: 0 1.5rem;
      .contents-group {
        display: flex;
        flex-direction: column;
        gap: 3.75rem;
        .contents-box {
          h2 {
            font-size: 1.75rem;
            font-weight: 800;
            color: #121212;
            line-height: 2.5rem;
            margin-bottom: 1rem;
            span {
              color: #b63fff;
            }
          }
          p {
            font-size: var(--fs-20);
            font-weight: 500;
            color: #252525;
            line-height: 2rem;
            span {
              color: #b63fff;
              font-weight: 700;
            }
          }
        }
      }
      .contents-detail {
        padding-top: 3.75rem;
        h2 {
          font-size: 1.75rem;
          font-weight: 800;
          color: #b63fff;
          line-height: 2.5rem;
          margin-bottom: 3rem;
        }
        .btn-box {
          .btn {
            max-width: 440px;
            width: 100%;
            font-size: 1.5rem;
            line-height: 2rem;
            padding: 1rem 0;
            border-radius: 50px;
            background-color: #b63fff;
            border: 1px solid #b63fff;
            &:hover {
              background-color: transparent;
              color: #b63fff;
            }
          }
        }
      }
    }
  }
  .contents-section3 {
    // padding: 5rem 1.5rem;
    .contents-container {
      height: 527px;
      img {
        width: 100%;
        height: 527px;
        object-fit: cover;
      }
    }
  }
  .contents-section4 {
    padding-top: 5rem;
    margin-bottom: 5rem;
    .contents-container {
      padding: 0 1.5rem;
      .contents-box {
        h2 {
          font-size: 1.75rem;
          font-weight: 800;
          color: #121212;
          line-height: 2.5rem;
          span {
            color: #b63fff;
          }
        }
        .contents-group {
          display: flex;
          flex-direction: column;
          gap: 3.75rem;
          .contents-item {
            .contents-item-img {
              max-height: 320px;
            }
            .contents-item-title {
              max-width: 384px;
              min-height: 24px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              gap: 0.5rem;
              margin-bottom: 0.5rem;
              .contents-item-icon {
                min-width: 19px;
                max-width: 19px;
              }
              .contents-item-type {
                background-color: #f0d9ff;
                border-radius: 0.25rem;
                padding: 0.25rem 0.5rem;
                font-size: var(--fs-12);
                font-weight: 800;
                color: #b63fff;
                line-height: 1rem;
              }
              p {
                font-size: var(--fs-12);
                font-weight: 500;
                color: #252525;
                line-height: 1rem;
              }
            }
          }
        }
      }
    }
  }
  .contents-section5 {
    padding: 5rem 0;
    background-color: #fbf5ff;
    .contents-container {
      padding: 0 1.5rem;
      .contents-box {
        .top-box {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          margin-bottom: 0.75rem;
          .number {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 32px;
            min-height: 32px;
            background-color: #b63fff;
            border-radius: 0.25rem;
            font-size: var(--fs-20);
            font-weight: 800;
            color: #fff;
            line-height: 1.25rem;
          }
          h2 {
            font-size: 1.625rem;
            font-weight: 800;
            color: #b63fff;
            line-height: 2.5rem;
            margin-bottom: 0;
          }
        }
        .title {
          margin-bottom: 1.5rem;
          h2 {
            font-size: 1.75rem;
            font-weight: 800;
            color: #121212;
            line-height: 2.5rem;
            margin-bottom: 0;
          }
        }
        .title-text {
          p {
            font-size: var(--fs-16);
            font-weight: 500;
            color: #252525;
            line-height: 2rem;
            .b-text {
              font-weight: 800;
            }
          }
        }
        .img-group {
          padding-top: 2rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 1.25rem;
          .img-item {
            max-height: 196px;
            img {
              max-height: 196px;
            }
            .gold-img {
              display: none;
            }
          }
        }
        .btn-box {
          padding-top: 2.75rem;
          margin-bottom: 5rem;
          .btn {
            max-width: 440px;
            width: 100%;
            font-size: var(--fs-20);
            font-weight: 700;
            line-height: 2rem;
            padding: 1rem 0;
            border-radius: 50px;
            background-color: #b63fff;
            border: 1px solid #b63fff;
            &:hover {
              background-color: transparent;
              color: #b63fff;
            }
          }
        }
        .sub-title {
          margin-bottom: 1rem;
          h2 {
            font-size: 1.625rem;
            font-weight: 700;
            color: #121212;
            line-height: 2.5rem;
            margin-bottom: 0;
          }
        }
        .sub-list {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 2rem;
          .sub-item {
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            background-color: #fff;
            border-radius: 1rem;
            padding: 1.125rem 1.5rem;
            .sub-icon {
              min-width: 64px;
              min-height: 64px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 35px;
              }
            }
            .sub-detail-box {
              display: flex;
            }
            .number {
              min-width: 23px;
              max-height: 23px;
              font-size: var(--fs-14);
              font-weight: 800;
              color: #d79262;
              line-height: 1.5rem;
              text-align: right;
              margin-left: 0.1875rem;
            }
            .sub-text {
              font-size: var(--fs-16);
              font-weight: 800;
              color: #252525;
              line-height: 1.5rem;
              margin-left: 0.8125rem;
            }
          }
        }
        .sub-info-text {
          font-size: var(--fs-20);
          font-weight: 700;
          color: #b63fff;
          line-height: 2rem;
          margin-bottom: 1rem;
        }
        .sub-info-box {
          margin-bottom: 5rem;
          p {
            font-size: var(--fs-14);
            font-weight: 500;
            color: #252525;
            line-height: 1.5rem;
          }
        }
        .sub-group {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding-top: 0.5rem;
        }
        .sub-group-list {
          .sub-group-box {
            background-color: #fff;
            border-radius: 1rem;
            padding: 1rem 1.25rem 2rem 0.5rem;
            h2 {
              font-size: 1.5rem;
              font-weight: 800;
              color: #b63fff;
              line-height: 2rem;
              padding-left: 0.75rem;
              margin-bottom: 0.5rem;
            }
            .sub-group-text {
              font-size: var(--fs-16);
              font-weight: 800;
              color: #b63fff;
              line-height: 1.5rem;
              padding-left: 0.75rem;
              margin-bottom: 1rem;
            }
            .sub-list-box {
              .sub-item {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                .sub-number {
                  min-width: 24px;
                  font-size: var(--fs-14);
                  font-weight: 800;
                  color: #121212;
                  line-height: 1.5rem;
                  text-align: right;
                }
                .sub-text {
                  font-size: var(--fs-14);
                  font-weight: 500;
                  color: #252525;
                  line-height: 1.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .contents-section6 {
    // padding-top: 5rem;
    margin-bottom: 5rem;
    .contents-container {
      .contents-box {
        padding-top: 5rem;
        .contents-top {
          padding: 0 1.5rem;
          margin-bottom: 1.5rem;
          .type-text {
            font-size: var(--fs-20);
            font-weight: 800;
            color: #d79262;
            line-height: 2.5rem;
          }
          .top-box {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin-bottom: 0.625rem;
            .number {
              display: flex;
              justify-content: center;
              align-items: center;
              min-width: 32px;
              min-height: 32px;
              background-color: #b63fff;
              border-radius: 0.25rem;
              font-size: var(--fs-20);
              font-weight: 800;
              color: #fff;
              line-height: 1.25rem;
            }
            h2 {
              font-size: 1.625rem;
              font-weight: 800;
              color: #b63fff;
              line-height: 2.5rem;
              margin-bottom: 0;
            }
            &.top-sub-box {
              align-items: flex-start;
              .number {
                margin-top: 0.5rem;
              }
            }
          }
          .top-text {
            p {
              font-size: var(--fs-16);
              font-weight: 500;
              color: #252525;
              line-height: 2rem;
            }
          }
        }
        .contents-img-box {
          .sub-group {
            background-color: #f5f5f5;
            border-radius: 1rem;
            padding: 1.5rem 1.25rem 2rem 0.5rem;
            margin: 2rem 1.5rem 0 1.5rem;
            .sub-list-box {
              .sub-item {
                display: flex;
                align-items: center;
                gap: 0.5rem;
                .sub-number {
                  min-width: 24px;
                  font-size: var(--fs-14);
                  font-weight: 800;
                  color: #d79262;
                  line-height: 1.5rem;
                  text-align: right;
                }
              }
            }
            .vertical-line {
              height: 0.5px;
              background-color: #d79262;
              margin: 1rem 1.5rem;
            }
            .detail-sub-text {
              padding: 0 0.5rem 0 2rem;
            }
            .sub-text {
              font-size: var(--fs-14);
              font-weight: 500;
              color: #252525;
              line-height: 1.5rem;
              &.special-text {
                font-weight: 700;
                color: #d79262;
              }
            }
          }
        }
      }
    }
  }
  .contents-section7 {
    .contents-container {
      .contents-box {
        background-color: #fbf4ef;
        padding: 5rem 1.5rem;
        .contents-icon {
          min-width: 44px;
          width: 44px;
          margin-bottom: 0.4375rem;
        }
        .contents-title-text {
          margin-bottom: 1.5rem;
        }
        h2 {
          font-size: var(--fs-24);
          font-weight: 800;
          color: #d79262;
          line-height: 2.5rem;
          margin-bottom: 0;
        }
        .btn-outline {
          max-width: 360px;
          width: 100%;
          font-size: var(--fs-16);
          font-weight: 700;
          line-height: 1.5rem;
          border-radius: 0.25rem;
          padding: 1rem 0;
          border: 2px solid #d79262;
        }
      }
    }
  }
  .contents-section8 {
    // padding-top: 5rem;
    margin-bottom: 7.5rem;
    .contents-container {
      background-color: #fbf2ff;
      padding: 5rem 1.5rem;
      .contents-img {
        margin-bottom: 1.5rem;
      }
      .contents-title-box {
        margin-bottom: 1.5rem;
      }
      h2 {
        font-size: var(--fs-24);
        font-weight: 800;
        color: #121212;
        line-height: 2.25rem;
        text-align: center;
        margin-bottom: 0;
        span {
          color: #b63fff;
        }
      }
      .btn {
        max-width: 440px;
        width: 100%;
        font-size: var(--fs-20);
        line-height: 2rem;
        padding: 1rem 0;
        border-radius: 50px;
        background-color: #b63fff;
        border: 1px solid #b63fff;
        &:hover {
          background-color: transparent;
          color: #b63fff;
        }
      }
    }
  }
}
// 치료 후기
#review {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
      }
      // &:before {
      //     content: '';
      //     position: absolute;
      //     top: 0;
      //     left: 0;
      //     right: 0;
      //     bottom: 0;
      //     background-color: rgba(0,0,0,0.3);
      //     z-index: 1;
      // }
    }
  }
  .contents-section {
    padding: 3rem 1.5rem 12.5rem 1.5rem;
    flex-grow: 1;
    .contents-container {
      .contents-box {
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #252525;
          line-height: 2.5rem;
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .text {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #252525;
          line-height: 1.625rem;
          text-align: center;
          margin-bottom: 1.625rem;
        }
        .contents-tab {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.625rem;
          margin-bottom: 1.5rem;
          li {
            flex: 1 1 auto;
            background-color: #d9d9d9;
            border-radius: 0.25rem;
            padding: 0.375rem 0.5rem;
            font-size: var(--fs-14);
            font-weight: 600;
            color: #252525;
            line-height: 0.875rem;
            text-align: center;
            cursor: pointer;
          }
        }
        .contents-list {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          gap: 2.5rem 1rem;
          .contents-item {
            width: calc(50% - 0.5rem);
            box-sizing: border-box;
            cursor: pointer;
            .contents-img {
              height: 120px;
              margin-bottom: 1rem;
              border-radius: 0.5rem;
              overflow: hidden;
              img {
                height: 120px;
                object-fit: cover;
                width: 100%;
              }
            }
            .tab {
              display: inline-block;
              background-color: #d9d9d9;
              border-radius: 0.25rem;
              padding: 0.375rem 0.5rem;
              font-size: var(--fs-12);
              font-weight: 500;
              color: #252525;
              line-height: 0.625rem;
              margin-bottom: 0.5rem;
            }
            .title {
              font-size: var(--fs-16);
              font-weight: 600;
              color: #252525;
              line-height: 1.5rem;
              margin-bottom: 0.5rem;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .day {
              font-size: var(--fs-12);
              font-weight: 500;
              color: #9a9a9a;
              line-height: 0.625rem;
            }
          }
          .blank-box {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            padding-top: 12.5rem;
            .icon {
              width: 64px;
            }
            p {
              font-size: var(--fs-14);
              font-weight: 400;
              color: #9a9a9a;
            }
          }
        }
      }
    }
  }
}
// 치료 후기 상세
#review-detail {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
      }
    }
  }
  .contents-section {
    padding: 3rem 1.5rem 12.5rem 1.5rem;
    flex-grow: 1;
    .contents-container {
      .contents-box {
        h2 {
          font-size: 1.625rem;
          font-weight: 800;
          color: #252525;
          line-height: 2.5rem;
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .text {
          font-size: var(--fs-16);
          font-weight: 500;
          color: #252525;
          line-height: 1.625rem;
          text-align: center;
          margin-bottom: 2.5rem;
        }
        .contents-title {
          padding: 1rem;
          border-top: 1px solid #bfbfbf;
          border-bottom: 1px solid #bfbfbf;
          .title {
            font-size: var(--fs-16);
            font-weight: 600;
            color: #252525;
            line-height: 1.875rem;
          }
          .tab {
            display: inline-block;
            background-color: #d9d9d9;
            border-radius: 0.25rem;
            padding: 0.375rem 0.5rem;
            font-size: var(--fs-12);
            font-weight: 500;
            color: #252525;
            line-height: 0.625rem;
            margin-bottom: 0.5rem;
          }
        }
        .contents-day {
          padding: 1rem;
          border-bottom: 1px solid #bfbfbf;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .day {
            display: flex;
            align-items: center;
            .icon {
              position: relative;
              width: 10px;
              height: 10px;
              img {
                position: absolute;
              }
            }
            p {
              font-size: var(--fs-12);
              font-weight: 500;
              color: #9a9a9a;
              line-height: 0.625rem;
              padding-left: 0.21875rem;
            }
          }
        }
        .contents-info {
          min-height: 480px;
          padding-top: 1.5rem;
          margin-bottom: 3.125rem;
          .contents-img {
            display: flex;
            justify-content: center;
            min-height: 450px;
            margin-bottom: 0.5rem;
            img {
              min-height: 450px;
              object-fit: cover;
            }
          }
          p {
            font-size: var(--fs-14);
            font-weight: 500;
            color: #252525;
            line-height: 1.5rem;
          }
        }
        .btn-outline {
          width: 100%;
          max-width: 312px;
          font-size: var(--fs-16);
          font-weight: 700;
          margin: 0 auto;
        }
      }
    }
  }
}
// 상담 예약 신청
#reservations {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
      }
    }
  }
  .contents-section {
    flex-grow: 1;
    .contents-container {
      padding: 2.625rem 1.25rem 12.5rem 1.25rem;
      .contents-list {
        display: flex;
        flex-direction: column;
        .contents-title {
          margin-bottom: 1.5rem;
          h2 {
            font-size: var(--fs-24);
            color: #252525;
            line-height: 2.25rem;
            margin-bottom: 1rem;
          }
          p {
            font-size: 1.25rem;
            font-weight: 500;
            color: #252525;
            line-height: 2rem;
          }
        }
        .contents-item {
          .agree {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .agree-title {
              display: flex;
              align-items: center;
              cursor: pointer;
              .check {
                padding-right: 0.65625rem;
                img {
                  width: 17.5px;
                }
              }
              p {
                font-size: var(--fs-18);
                font-weight: 500;
                color: #252525;
                line-height: 2rem;
              }
            }
            .arrow {
              width: 24px;
              height: 24px;
              display: flex;
              cursor: pointer;
              .arrow-rotate {
                transform: rotate(-180deg);
              }
            }
          }
          .contents-info {
            padding: 1.5rem 1.5rem 3rem 1.5rem;
            margin-top: 1.5rem;
            border: 1px solid #bfbfbf;
            .text-box {
              .title {
                font-weight: 700;
                margin-bottom: 2rem;
              }
              p {
                font-size: var(--fs-14);
                font-weight: 500;
                color: #252525;
                line-height: 1.5rem;
              }
              .text {
                margin-bottom: 2rem;
              }
              .detail {
                margin-bottom: 2rem;
                li {
                  list-style: inside;
                  font-size: var(--fs-14);
                  font-weight: 500;
                  color: #252525;
                  line-height: 1.5rem;
                  padding-left: 0.5rem;
                }
              }
              .sub-box {
                padding: 0.5rem;
                margin: 1.375rem 0 1.875rem 0;
                background-color: #f1f1f1;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
                li {
                  list-style: inside;
                  font-size: var(--fs-12);
                  font-weight: 500;
                  color: #252525;
                  line-height: 1.5rem;
                }
              }
            }
          }
          .btn-box {
            padding-top: 1.875rem;
            button {
              width: 100%;
              border-radius: 0.5rem;
              color: #fff;
              &:hover {
                color: #dba075;
                background-color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}
// 상담 예약 상세
#reservations-write {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
      }
    }
  }
  .contents-section {
    flex-grow: 1;
    .contents-container {
      padding: 0 1.25rem 12.5rem 1.25rem;
      .contents-list {
        display: flex;
        flex-direction: column;
        .contents-title {
          margin-bottom: 1.875rem;
          h2 {
            font-size: var(--fs-24);
            color: #252525;
            line-height: 2.25rem;
            padding-top: 2.625rem;
            margin-bottom: 1.125rem;
          }
          p {
            font-size: var(--fs-18);
            font-weight: 500;
            color: #252525;
            line-height: 1.625rem;
          }
        }
        .contents-item {
          .input-box {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            span {
              display: none;
            }
            input {
              width: 100%;
              background-color: #e8e8e8;
              border-radius: 0.5rem;
              border: none;
              outline: none;
              font-size: var(--fs-18);
              font-weight: 400;
              color: #646464;
              line-height: 2rem;
              padding: 0.75rem 1.25rem;
            }
            .sub-input {
              width: 100%;
              display: flex;
              gap: 1rem;
              .select {
                width: 50%;
              }
            }
            .text-area {
              width: 100%;
              background-color: #e8e8e8;
              border-radius: 0.5rem;
              border: none;
              outline: none;
              font-size: var(--fs-18);
              font-weight: 400;
              color: #646464;
              line-height: 2rem;
              padding: 0.75rem 1.25rem;
            }
            .text-red {
              font-size: var(--fs-16);
              color: #ff7878 !important;
              line-height: 0.875rem;
            }
          }
          .input-box {
            select::placeholder,
            input::placeholder,
            textarea::placeholder {
              font-size: 14px;
            }
          }
          .btn-box {
            padding-top: 2.5rem;
            button {
              width: 100%;
              border-radius: 0.5rem;
              font-size: var(--fs-18);
            }
          }
        }
      }
    }
  }
}
// 상담 예약 성공
#reservations-clear {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
      }
    }
  }
  .contents-section {
    flex-grow: 1;
    .contents-container {
      padding: 0 1.25rem;
      flex-grow: 1;
      .contents-list {
        padding: 10.125rem 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .contents-title {
          text-align: center;
          margin-bottom: 1.875rem;
          h2 {
            font-size: var(--fs-24);
            color: #252525;
            line-height: 2.25rem;
            padding-top: 2.625rem;
            margin-bottom: 1.125rem;
          }
          p {
            font-size: var(--fs-18);
            font-weight: 500;
            color: #252525;
            line-height: 1.625rem;
            span {
              display: block;
            }
          }
        }
        .contents-img {
          width: 88px;
          height: 88px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
// 자주 묻는 질문
#faqs {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
      }
    }
  }
  .contents-section {
    .contents-container {
      .contents-list {
        padding: 2.5rem 1.5rem 12.5rem 1.5rem;
        h2 {
          font-size: var(--fs-24);
          font-weight: 700;
          color: #252525;
          line-height: 2.25rem;
          text-align: center;
          padding-bottom: 2.5rem;
          border-bottom: 1px solid #bfbfbf;
        }
        .contents-item {
          padding: 1rem 0.5625rem 1rem 0.75rem;
          border-bottom: 1px solid #bfbfbf;
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            .info {
              width: 80%;
              font-size: var(--fs-18);
              font-weight: 700;
              color: #252525;
              line-height: 2.25rem;
            }
            .icon {
              width: 20%;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              .toggle-arrow {
                // transition: transform 0.1s ease;
                width: 12px;
                height: 8px;
                margin-left: 1rem;
                &.rotated {
                  transform: rotate(-180deg);
                }
              }
            }
          }
          .info-detail {
            font-size: var(--fs-16);
            font-weight: 500;
            color: #252525;
            line-height: 1.5rem;
            padding-top: 0.5rem;
          }
        }
        .btn-outline {
          max-width: 312px;
          margin: 0 auto;
          padding-top: 2.5rem;
          .btn-outline-primary {
            width: 100%;
            font-size: var(--fs-20);
            font-weight: 700;
            line-height: 2rem;
          }
        }
      }
    }
  }
}
// 공지사항
#notices {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
      }
    }
  }
  .contents-section1 {
    padding: 2.5rem 1.5rem 0 1.5rem;
    flex-grow: 1;
    .contents-container {
      .contents-list {
        h2 {
          font-size: var(--fs-24);
          font-weight: 700;
          color: #252525;
          line-height: 2.25rem;
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .text {
          font-size: var(--fs-14);
          font-weight: 400;
          color: #252525;
          line-height: 0.875rem;
          text-align: center;
        }
        .contents-fix {
          padding-top: 2.375rem;
          .contents-item {
            position: relative;
            background-color: #e7e7e7;
            padding: 1.5625rem 2.625rem 1.5625rem 1.5rem;
            margin-bottom: 0.0625rem;
            cursor: pointer;
            .title {
              font-size: var(--fs-18);
              font-weight: 700;
              color: #252525;
              line-height: 1.625rem;
              margin-bottom: 0.5rem;
            }
            .day {
              font-size: var(--fs-12);
              font-weight: 500;
              color: #9a9a9a;
              line-height: 0.75rem;
            }
            .icon {
              position: absolute;
              right: 1rem;
              bottom: 1rem;
              width: 15px;
              height: 15px;
              display: flex;
            }
          }
        }
      }
    }
  }
  .contents-section2 {
    padding: 0 1.5rem;
    .contents-container {
      .contents-list {
        .contents-item {
          position: relative;
          padding: 1.5625rem 2.625rem 1.5625rem 1.5rem;
          border-bottom: 1px solid #bfbfbf;
          cursor: pointer;
          .title {
            font-size: var(--fs-18);
            font-weight: 700;
            color: #252525;
            line-height: 1.625rem;
            margin-bottom: 0.5rem;
          }
          .day {
            font-size: var(--fs-12);
            font-weight: 500;
            color: #9a9a9a;
            line-height: 0.75rem;
          }
        }
        .contents-none {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1rem;
          padding-top: 7.5rem;
          margin-bottom: 15rem;
          .icon {
            width: 56px;
            height: 56px;
          }
          p {
            font-size: var(--fs-16);
            font-weight: 600;
            color: #a5a5a5;
            line-height: 1rem;
          }
        }
      }
      .d-flex {
        margin-bottom: 11.5rem;
      }
    }
  }
}
// 공지사항 상세
#noticeDetail {
  .main-section {
    padding-top: 5rem;
    .main-img {
      position: relative;
      height: 176px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
        height: 176px;
        object-fit: cover;
      }
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
      }
    }
  }
  .contents-section {
    padding: 2.5rem 1.5rem 12.5rem 1.5rem;
    flex-grow: 1;
    .contents-container {
      .contents-box {
        h2 {
          font-size: var(--fs-24);
          font-weight: 700;
          color: #252525;
          line-height: 2.25rem;
          text-align: center;
          margin-bottom: 0.5rem;
        }
        .text {
          font-size: var(--fs-14);
          font-weight: 400;
          color: #252525;
          line-height: 0.875rem;
          text-align: center;
          margin-bottom: 2.375rem;
        }
        .contents-title {
          padding: 1.75rem 0.75rem;
          border-top: 1px solid #bfbfbf;
          border-bottom: 1px solid #bfbfbf;
          .title {
            font-size: var(--fs-18);
            font-weight: 700;
            color: #252525;
            line-height: 1rem;
          }
        }
        .contents-day {
          padding: 1rem;
          border-bottom: 1px solid #bfbfbf;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          .day {
            display: flex;
            align-items: center;
            .icon {
              position: relative;
              width: 10px;
              height: 10px;
              img {
                position: absolute;
              }
            }
            p {
              font-size: var(--fs-12);
              font-weight: 500;
              color: #9a9a9a;
              line-height: 0.625rem;
              padding-left: 0.21875rem;
            }
          }
        }
        .contents-img {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 1.5rem;
          padding: 1.5rem 0;
        }
        .contents-text {
          font-size: var(--fs-14);
          font-weight: 500;
          color: #252525;
          line-height: 1.5rem;
          margin-bottom: 5rem;
        }
        .btn-outline {
          width: 100%;
          max-width: 312px;
          font-size: var(--fs-20);
          font-weight: 700;
          line-height: 2rem;
          border-radius: 0.5rem;
          margin: 0 auto;
        }
      }
    }
  }
}
// 개인정보 보호 정책
#private {
  .main-section {
    padding-top: 5rem;
    flex-grow: 1;
    .main-contents {
      padding: 1.5rem 1.5rem 0 1.5rem;
      margin-bottom: 12.5rem;
      h2 {
        font-size: var(--fs-24);
        font-weight: 700;
        color: #252525;
        line-height: 2.25rem;
        text-align: center;
        margin-bottom: 2.5rem;
      }
      #myIframe {
        // min-height: 4000px;
      }
    }
  }
}

@media (min-width: 768px) {
  /* 태블릿 대응 스타일 */
}

// PC 미디어 쿼리
@media (min-width: 1024px) {
}

@media (min-width: 1200px) {
  /* 대형 데스크탑 대응 스타일 */
}
