// 모달 창 생성시 스크롤 제거
.no-scroll {
  overflow: hidden !important;
}
// basic modal
.basic-modal {
  width: 100%;
  max-width: 600px;
  position: relative;
  background: #fff;
  padding: 3.5rem 1.5rem;
  margin: 0 1.5rem;
  z-index: 3;
  border-radius: 1rem;
  .close-btn {
    width: 32px;
    position: absolute;
    top: 1rem;
    right: 0.75rem;
    cursor: pointer;
  }
  .basic-container {
    h2 {
      font-size: var(--fs-20);
      font-weight: 700;
      color: #252525;
      line-height: 1.25rem;
      text-align: center;
      margin-bottom: 1rem;
    }
    p {
      font-size: var(--fs-16);
      font-weight: 500;
      color: #252525;
      line-height: 1rem;
      text-align: center;
    }
  }
}
// popup modal
.popup-modal {
  // width: 100%;
  // max-width: 600px;
  position: relative;
  background: transparent;
  margin: 0 1.5rem;
  z-index: 3;
  overflow: hidden;
  .close-btn {
    width: 32px;
    position: absolute;
    top: 1rem;
    right: 0.75rem;
    cursor: pointer;
  }
  .popup-container {
    .popup-contents {
      padding: 0.5rem 1.5rem;
      background-color: #FFF;
      .form {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        input {
          width: 16px;
          height: 16px;
        }
        label {
          font-size: var(--fs-16);
          color: #252525;
        }
      }
      .pointer {
        font-size: var(--fs-18);
        color: #DBA075;
      }
    }
  }
}

// login modal
.login-modal {
  width: calc(100% - 3rem);
  max-width: 600px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 3.5rem 1.5rem;
  z-index: 3;
  // 기존 코드 임시 주석
  // width: 100%;
  // max-width: 600px;
  // position: relative;
  // background: #fff;
  // padding: 3.5rem 1.5rem;
  // margin: 0 1.5rem;
  // z-index: 3;
  .close-btn {
    width: 32px;
    position: absolute;
    top: 1rem;
    right: 0.75rem;
    cursor: pointer;
  }
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      width: 40px;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: var(--fs-16);
      font-weight: 500;
      color: #252525;
      line-height: 1.625rem;
      text-align: center;
      span {
        font-weight: 700;
        color: #D79262;
      }
    }
    .text {
      margin-bottom: 1rem;
    }
    .login-box {
      width: 100%;
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      .login {
        width: 100%;
        max-width: 264px;
        .login-btn {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.5rem;
          border: 1px solid #23140A;
          border-radius: 0.25rem;
          font-size: var(--fs-16);
          font-weight: 500;
          color: #23140A;
          line-height: 1rem;
          padding: 1rem 0;
          .login-icon {
            width: 25px;
          }
        }
      }
    }
  }
}

// confirm-modal
.confirm-modal {
  width: 100%;
  max-width: 480px;
  position: relative;
  background: #fff;
  border-radius: 1rem;
  margin: 0 2.5rem;
  z-index: 3;
  .confirm-title {
    padding: 1.75rem 1.75rem 1.5rem 1.75rem;
    h2 {
      font-size: var(--fs-20);
      font-weight: 700;
      color: #252525;
      line-height: 1.25rem;
      text-align: center;
      margin-bottom: 1rem;
    }
    p {
      font-size: var(--fs-16);
      font-weight: 500;
      color: #252525;
      line-height: 1rem;
      text-align: center;
    }
  }
  .confirm-box {
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid #BFBFBF;
    .confirm-btn {
      width: 50%;
      font-size: var(--fs-20);
      font-weight: 500;
      color: #252525;
      line-height: 1.375rem;
      text-align: center;
      padding: 1rem 0;
      cursor: pointer;
      &.blue {
        color: #297BFE;
      }
      &:not(:last-child) {
        border-right: 1px solid #BFBFBF; /* 오른쪽 테두리 추가 */
      }
    }
  }
}